<template>
    <SingleTextEntry 
        act_id="act_18" 
        :scene_id="5"
        intro_text='Tras este proceso y  evolución...'
        title='Diagnóstico del paciente'
        question="¿Cuál fue el diagnóstico?"
        :feedback="feedback_content"
        incorrect_msg="La respuesta es incorrecta"
        :option="[
            {
                id:0,
                solution: 'SCACEST',
                value: '',
            }
        ]"
        clue_text="Ahora recuerda y ordena las letras que has ido anotando"
        :final_activity="true">
    </SingleTextEntry>
</template>

<script>
import SingleTextEntry from '../activitytype/SingleTextEntry';
import Vue from 'vue';
import FeedbackEsc5Act3 from '../feedbacks/FeedbackEsc5Act3';

export default {
    components: {
        SingleTextEntry
    },
    data:function(){
        return {
            feedback_content: "",
        };
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "scene5");
        vueapp.$store.commit("setLastURL", "activity5_3");
        setActivityVisited('scene2', 'act_18');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackEsc5Act3)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;
    }
}
</script>