<template>
    
</template>

<script>
import moment from 'moment';

export default {
    props: {
        final_activity: {
            type:Boolean,
            default: false,
            required: false,
        },
        act_id: {
            type: String,
            required: true,
            default: ''
        },
        scene_id: {
            type: Number,
            required: true,
            default: ''
        },
        clue_text: {
            type: String,
            required: true,
            default: '',
        },
        intro_text: {
            type: String,
            required: true,
            default: '',
        },
        right_size: {
            type: String,
            required: false,
            default: 'col-6'
        },
        title: {
            type: String,
            required: true,
            default: ''
        },
        question: {
            type: String,
            required: true,
            default: '',
        },
        feedback: {
            type: String,
            required: true,
            default: ''
        },
        feedback_img: {
            type: String,
            required: false,
            default: ''
        },
        incorrect_msg: {
            type: String,
            required: true,
            default: ''
        },
        isEnd:{
            type: Boolean,
            required: false,
            default: false,
        },
        clue_time: {
            type: Number,
            required: false,
            default: 30
        },
        row_style:{
            type: String,
            required: false,
            default: "60px",
        },
        img_style: {
            type: String,
            required: false
        }
    },
    data: function() {
        return {
            is_correct: null,
            clue: false,
            can_clue: false,
            last_clue_date: moment(new Date()),
            current_date: moment(new Date()),
            render: false,
            activity_response: null,
        }
    },
    mounted: function() {
        this.canClueListener();
        this.$dispatcher.setSignalHandler('updateClues', this.updateClueDates);
    },
    methods: {
        canClueListener: function() {
            //hay que jugar con this.canClue
            this.last_clue_date = moment(0);

            if (vueapp.$store.state.clue_timestamp != null) this.last_clue_date = moment(vueapp.$store.state.clue_timestamp);
            this.current_date = moment(new Date());

            if (this.current_date.diff(this.last_clue_date, 'seconds') > this.cluetime) {
                this.canClue = true;
            } else {
                this.canClue = false;
            }
        },
        getClue: function(param) {
            this.clue = true;
            setActivityStatus(this.scene_id, this.act_id, this.$data);
            vueapp.$store.commit("setClueTimestamp", moment().format('YYYY-MM-DD HH:mm:ss'));
        },
        updateClueDates: function() {
            this.current_date = moment(new Date());
        }
    }
}
</script>

<style>
</style>