import Vue from 'vue'
import Vuex from 'vuex'
import 'es6-promise/auto'

Vue.use(Vuex);

const getDefaultState = () => {
    return {
        //Global timer
        timer: null,
        timer_interval: null,
        //CountDown timer
        countdown: 3,
        countdown_interval: null,
        //Game state
        game_state: null,
        /*
            starting    - The game is on the initial countdown view
            started     - The game is already initialized, the other countdown on the navbar already got fired.
            finished    - The game is finished.
        */
        //Last saved url
        last_url: null,
        //Scenes
        scenes: {
            scene1:{
                activities: {},
                locked: false,
                completed: false,
                scene: null
            },
            scene2:{
                activities: {},
                locked: true,
                completed: false,
                scene: null
            },
            scene3:{
                activities: {},
                locked: true,
                completed: false,
                scene: null
            },
            scene4:{
                activities: {},
                locked: true,
                completed: false,
                scene: null
            },
            scene5:{
                activities: {},
                locked: true,
                completed: false,
                scene: null
            },
        },
        //Back URL
        back_url: null,
        //Clue timestamp
        clue_timestamp: null,
        //The game is over
        game_over: true,
        score: 0,
        //Beep sound
        beep: null,
        end: null,
        error: null,
        correct: null,
        gameover: null,
        //Image assets
        images: null,
        //Full screen message
        fs_message: true,
    }
}


// initial state
const state = getDefaultState();

const store = new Vuex.Store({
    state,
    getters: {},
    mutations: {
		initialiseStore(state) {
			// Check if the ID exists
			if(localStorage.getItem('store'+window.game_id)) {
				// Replace the state object with the stored item
				this.replaceState(
					Object.assign(state, JSON.parse(localStorage.getItem('store'+window.game_id)))
				);
			}
		},
        resetGlobalState(state) {
            Object.assign(state, getDefaultState())
        },
        setCountDown(state, param) {
            state.countdown = param;
        },
        setTimerInterval(state, param) {
            state.timer_interval = param;
        },
        setCountDownInterval(state, param){
            state.countdown_interval = param;
        },
        setTimer(state, param) {
            state.timer = param;
        },
        setGameState(state, param) {
            state.game_state = param;
        },
        setLastURL(state, param) {
            state.last_url = param;
        },
        setScenes(state, param) {
            state.scenes = param;
        },
        setBackURL(state, param) {
            state.back_url = param;
        },
        setClueTimestamp(state, param) {
            state.clue_timestamp = param;
        },
        setGameOver(state, param) {
            state.game_over = param;
        },
        setScore(state, param) {
            state.score = param;
        },
        setBeepSound(state, param) {
            state.beep = param;
        },
        setEndSound(state, param) {
            state.end = param;
        },
        setErrorSound(state, param) {
            state.error = param;
        },
        setCorrectSound(state, param) {
            state.correct = param;
        },
        setGameOverSound(state, param) {
            state.gameover = param;
        },
        setImageAssets(state, param) {
            state.images = param;
        },
        setFullScreenMessage(state, param) {
            state.fs_message = param;
        },
    },
    actions: {}
})

store.subscribe((mutation, state) => {
	localStorage.setItem('store'+window.game_id, JSON.stringify(state));
});

export default store