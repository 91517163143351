<template>
    <div :class="[(([
        'activity1_1',
        'activity1_2',
        'activity1_3',
        'activity1_4',
        'activity1_5',
        'activity2_1',
        'activity2_2',
        'activity2_3',
        'activity2_4',
        'activity3_1',
        'activity3_2',
        'activity3_3',
        'activity4_1',
        'activity4_2',
        'activity4_3',
        'activity4_4',
        'activity5_1',
        'activity5_2',
        'activity5_3',
        'activity5_3'].indexOf($route.name) > -1) ? 'bg-feedback':'')]" :style="cssProps">
        <div class="h-100 w-100" >
            <router-view></router-view>
        </div>
    </div>
</template>

<script>    
    export default {
        name: 'Game',
        data(){
            return {
                cssProps: {
                    width: '100%',
                    height: '100%',
                    overflow: 'auto !important',
                },
                caso_clinico: process.env.VUE_APP_GAME_ID
            };
        },
        methods: {
            /*Start: function(){
                router.push({name:"countdown"}).catch((e)=>{console.log(e)});
            }*/
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .game{
        width: 100%;
        height: 100%;
        overflow: auto !important;
    }
</style>
