<template>
    <div class="w-100 h-100 animate__animated animate__fadeIn">
       <div class="row w-100 h-100 m-0" v-if="(activity_response==null || activity_response ==false)">
            <div :class="left_size">
                <div class="activity_title  question-title text-rockwell">{{ title }}</div>
                <div class="intro_text ml-5 mt-4 text-oswald" v-html="intro_text"></div>

                <div v-if="!clue && clue_text != null" class="d-flex justify-content-center align-items-center flex-column mt-5 ml-5 text-rockwell">
                    <button
                        :disabled="(current_date.diff(last_clue_date, 'seconds') < clue_time)" 
                        class="rounded pt-2 pb-2 pr-5 pl-5 clues clue-button" 
                        v-on:click="getClue">
                        ¡PISTA!
                    </button>
                </div>
                <div class="row text-rockwell" v-if="clue && clue_text != null">
                    <div class="rounded p-2 w-100 clues clue-show justify-content-center align-items-center mt-5 ml-5 text-rockwell">
                        ¡PISTA!
                        <div class="text-oswald" v-html="clue_text"></div>
                    </div>
                </div>
                <p class="ml-5 intro_text_order order-warning text-center text-information-order clue-msg"  v-if="!clue && (current_date.diff(last_clue_date, 'seconds') < clue_time) && clue_text != null">Hace muy poco que pediste una pista, inténtalo más tarde</p>
            </div>
            <div :class="right_size">
                <h3 class="text-rockwell question-title">{{ question }}</h3>
                <div class="col mt-4 text-center">
                    <input type="text" class="input-escaperoom p-2 rounded" placeholder="Introduce la palabra" v-model="option[0].value">
                </div>
                <div class="row m-0 d-flex justify-content-center align-items-center">
                    <button v-if="activity_response == null" class="btn btn-action mt-3 mb-3 rounded check-btn" v-on:click="check_activity">Comprobar</button>
                    <div v-if="activity_response==false" class="incorrectmsg p-2 mt-3 result text-center rounded">
                        {{ incorrect_msg }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="activity_response==true" class="w-100 h-100 d-flex justify-content-center flex-column">
            <div class="w-100 h-100" v-html="feedback"></div>
            <div class="w-100 d-flex justify-content-center align-items-center scene-finisher-btn">
                <button v-if="scenario_finisher == true" @click="$router.push({name:'map'})" class="btn btn-action mt-3 mb-3 rounded check-btn">Volver a la pantalla principal</button>
            </div>
        </div>
    </div>
</template>

<script>
import ActivityType from './ActivityType';

export default {
    extends: ActivityType,
    props: {
        act_id: 0,
        scene_id: 0,
        intro_text: '',
        option: {
            type: Object,
            default: {}
        },
        correct_answer: {
            type: Number,
            default: 0,
        },
        clue_text:{
            type:String,
            required: false,
        },
        incorrect_msg: '',
        left_size: {
            type: String,
            default: 'col-6'
        },
        right_size: {
            type: String,
            default: 'col-6'
        },
        scenario_finisher: {
            type: Boolean,
            default: false,
        },
        next_scenario: {
            type: Number,
            default: 0,
        },
        final_activity: {
            type:Boolean,
            default: false,
            required: false,
        },
    },
    data: function() {
        return {
            checked_option: [],
            activity_response: null
        }
    },
    mounted: function() {        
        var act = null;
        act = getActivityStatus(this.scene_id, this.act_id);
        if(act != null){
            if(act.data != null){
                this.activity_response = act.data.activity_response;
                this.can_clue = act.data.can_clue;
                this.checked_options = act.data.checked_options;
                this.clue = act.data.clue;
                this.current_date = act.data.current_date;
                this.is_correct = act.data.is_correct;
                this.last_clue_date = act.data.last_clue_date;
                this.render = act.data.render;
            }
        }
    },
    methods: {
        check_activity: function() {
            var valid = true;
            if(this.option[0].solution.toUpperCase() != this.option[0].value.toUpperCase()){
                valid = false;
            }
            
            if(valid){
                this.activity_response = true;
                this.is_correct = true;
                setActivityStatus(this.scene_id, this.act_id, this.$data);
                var act = getActivityStatus(this.scene_id, this.act_id);
                if(act.completed == false)
                    vueapp.$store.commit("setScore", vueapp.$store.state.score + 1);
                setActivityCompleted("scene"+this.scene_id, this.act_id, true);
                if(this.scenario_finisher == true){
                    setSceneLocked("scene"+this.next_scenario, false);
                    setSceneCompleted("scene"+(this.next_scenario-1), true);
                }
                if(this.final_activity == true){
                    vueapp.$store.commit("setGameOver", false);
                    window.finishGame();
                }
                if(this.$store.state.correct != null)
                    this.$store.state.correct.play();
            }else{
                this.activity_response = false;
                this.is_correct = false;
                setTimeout(function(){
                    this.activity_response = null;
                    this.is_correct = null;
                }.bind(this), 3000);
                if(this.$store.state.error != null)
                    this.$store.state.error.play();
            }
        },
        finishGame: function(){
            finishGame();
        }
    }
}
</script>
