var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['row p-2', (([
            'scene1',
            'scene2',
            'scene3',
            'scene4',
            'scene5',
            'countdown',
            'activity1_1',
            'activity1_2',
            'activity1_3',
            'activity1_4',
            'activity1_5',
            'activity2_1',
            'activity2_2',
            'activity2_3',
            'activity2_4',
            'activity3_1',
            'activity3_2',
            'activity3_3',
            'activity4_1',
            'activity4_2',
            'activity4_3',
            'activity4_4',
            'activity5_1',
            'activity5_2',
            'activity5_3',
            'activity5_3',
            'textentry-activity',
            'order-activity',
            'checks-activity',
            'qanda-activity',
            'radio-activity',
            'end',
            'locked' ].indexOf(_vm.$route.name) > -1) ? 'escaperoom-main-bg animate__animated animate__fadeIn':'')],attrs:{"id":"navigationbar"}},[_c('b-modal',{attrs:{"id":"modal-center","centered":"","hide-footer":"","title-html":"<span class='text-white text-rockwell'>¡Cuidado!</span>"}},[_c('p',{staticClass:"my-4 text-oswald"},[_vm._v("¿Esta seguro que desea reiniciar el juego?")]),_c('hr'),_c('div',{staticClass:"col p-0"},[_c('b-button',{staticClass:"float-right btn escaperoom-main-bg ml-2",on:{"click":function($event){return _vm.resetDemo('modal-center')}}},[_vm._v("Continuar")]),_c('b-button',{staticClass:"float-right btn btn-escaperoom",on:{"click":function($event){return _vm.hideModal('modal-center')}}},[_vm._v("Cancelar")])],1)]),_c('b-modal',{ref:"modal-help",attrs:{"size":"xl","id":"modal-help","centered":"","hide-footer":"","title-html":"<span class='text-white text-rockwell'>Ayuda</span>"}},[_c('help')],1),_c('div',{staticClass:"user-info col-5 h-100"},[_c('div',{staticClass:"h-100 d-flex justify-content-start align-items-center w-100"},[_c('logolongcolor',{class:['logo-long ml-4', (([
            'scene1',
            'scene2',
            'scene3',
            'scene4',
            'scene5',
            'countdown',
            'activity1_1',
            'activity1_2',
            'activity1_3',
            'activity1_4',
            'activity1_5',
            'activity2_1',
            'activity2_2',
            'activity2_3',
            'activity2_4',
            'activity3_1',
            'activity3_2',
            'activity3_3',
            'activity4_1',
            'activity4_2',
            'activity4_3',
            'activity4_4',
            'activity5_1',
            'activity5_2',
            'activity5_3',
            'activity5_3',
            'textentry-activity',
            'order-activity',
            'checks-activity',
            'qanda-activity',
            'radio-activity',
            'end',
            'locked' ].indexOf(_vm.$route.name) > -1) ? 'd-none':'d-block')],attrs:{"id":"logo-long-color"}}),_c('logolongblanco',{class:['logo-long ml-4', (([
            'scene1',
            'scene2',
            'scene3',
            'scene4',
            'scene5',
            'countdown',
            'activity1_1',
            'activity1_2',
            'activity1_3',
            'activity1_4',
            'activity1_5',
            'activity2_1',
            'activity2_2',
            'activity2_3',
            'activity2_4',
            'activity3_1',
            'activity3_2',
            'activity3_3',
            'activity4_1',
            'activity4_2',
            'activity4_3',
            'activity4_4',
            'activity5_1',
            'activity5_2',
            'activity5_3',
            'activity5_3',
            'textentry-activity',
            'order-activity',
            'checks-activity',
            'qanda-activity',
            'radio-activity',
            'end',
            'locked' ].indexOf(_vm.$route.name) > -1) ? 'd-block':'d-none')],attrs:{"id":"logo-long-blanco"}}),(_vm.$store.state.game_state != null)?_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-center",modifiers:{"modal-center":true}}],class:['btn rounded mb-0 ml-3 text-oswald', (([
                'scene1',
                'scene2',
                'scene3',
                'scene4',
                'scene5',
                'countdown',
                'activity1_1',
                'activity1_2',
                'activity1_3',
                'activity1_4',
                'activity1_5',
                'activity2_1',
                'activity2_2',
                'activity2_3',
                'activity2_4',
                'activity3_1',
                'activity3_2',
                'activity3_3',
                'activity4_1',
                'activity4_2',
                'activity4_3',
                'activity4_4',
                'activity5_1',
                'activity5_2',
                'activity5_3',
                'activity5_3',
                'textentry-activity',
                'order-activity',
                'checks-activity',
                'qanda-activity',
                'radio-activity',
                'end',
                'locked'].indexOf(_vm.$route.name) > -1) ? 'btn-nav':'btn-nav-red')]},[_vm._v("Volver a comenzar")]):_vm._e()],1)]),_c('div',{staticClass:"clock col-2 h-100 d-flex justify-content-center align-items-center"},[(_vm.$store.state.timer)?_c('div',{staticClass:"row no-gutters clock-container"},[_c('div',{staticClass:"align-middle time-text"},[_c('FlipDown',{attrs:{"type":2,"endDate":_vm.$store.state.timer},on:{"timeUp":_vm.onFinished}})],1)]):_vm._e()]),_c('div',{staticClass:"col-5 h-100 d-flex justify-content-end align-items-center"},[_c('button',{ref:"fsbtn",class:['btn rounded mb-0 ml-3 text-oswald', (([
                'scene1',
                'scene2',
                'scene3',
                'scene4',
                'scene5',
                'countdown',
                'activity1_1',
                'activity1_2',
                'activity1_3',
                'activity1_4',
                'activity1_5',
                'activity2_1',
                'activity2_2',
                'activity2_3',
                'activity2_4',
                'activity3_1',
                'activity3_2',
                'activity3_3',
                'activity4_1',
                'activity4_2',
                'activity4_3',
                'activity4_4',
                'activity5_1',
                'activity5_2',
                'activity5_3',
                'activity5_3',
                'textentry-activity',
                'order-activity',
                'checks-activity',
                'qanda-activity',
                'radio-activity',
                'end',
                'locked'].indexOf(_vm.$route.name) > -1) ? 'btn-nav':'btn-nav-red')],attrs:{"type":"button","tooltip":[(_vm.$store.state.fs_message) ? '¡Para una mejor experiencia, utilice la pantalla completa!':''],"flow":[(_vm.$store.state.fs_message)?'left':''],"id":"fsbtn"},on:{"click":_vm.toggleFullScreen}},[(_vm.is_fullscreen == false)?_c('font-awesome-icon',{attrs:{"icon":['fas', 'expand']}}):_vm._e(),_vm._v(" "),(_vm.is_fullscreen == true)?_c('font-awesome-icon',{attrs:{"icon":['fas', 'compress']}}):_vm._e(),_vm._v(" "+_vm._s(_vm.text_btn_fs)+" ")],1),([
            'scene1',
            'scene2',
            'scene3',
            'scene4',
            'scene5' ].indexOf(_vm.$route.name) > -1)?_c('button',{class:['btn rounded mb-0 ml-3 text-oswald', (([
                'scene1',
                'scene2',
                'scene3',
                'scene4',
                'scene5',
                'countdown',
                'activity1_1',
                'activity1_2',
                'activity1_3',
                'activity1_4',
                'activity1_5',
                'activity2_1',
                'activity2_2',
                'activity2_3',
                'activity2_4',
                'activity3_1',
                'activity3_2',
                'activity3_3',
                'activity4_1',
                'activity4_2',
                'activity4_3',
                'activity4_4',
                'activity5_1',
                'activity5_2',
                'activity5_3',
                'activity5_3',
                'textentry-activity',
                'order-activity',
                'checks-activity',
                'qanda-activity',
                'radio-activity',
                'end',
                'locked'].indexOf(_vm.$route.name) > -1) ? 'btn-nav':'btn-nav-red'), 'btn-nav-generic'],on:{"click":function($event){return _vm.$router.push({name:'map'})}}},[_vm._v("Volver a la pantalla principal")]):_vm._e(),([
            'activity1_1',
            'activity1_2',
            'activity1_3',
            'activity1_4',
            'activity1_5',
            'activity2_1',
            'activity2_2',
            'activity2_3',
            'activity2_4',
            'activity3_1',
            'activity3_2',
            'activity3_3',
            'activity4_1',
            'activity4_2',
            'activity4_3',
            'activity4_4',
            'activity5_1',
            'activity5_2',
            'activity5_3',
            'activity5_3',
            'textentry-activity',
            'order-activity',
            'checks-activity',
            'qanda-activity',
            'radio-activity',
            'locked' ].indexOf(_vm.$route.name) > -1)?_c('button',{staticClass:"btn rounded mb-0 ml-3 btn-nav text-oswald",on:{"click":_vm.goBack}},[_vm._v("Volver")]):_vm._e(),([
            'scoreboard'
            ].indexOf(_vm.$route.name) > -1 && _vm.$store.state.user.role == 1)?_c('button',{staticClass:"btn btn-primary btn-action rounded-pill",on:{"click":_vm.goBack}},[_vm._v("Volver")]):_vm._e(),_c('button',{class:['btn text-oswald ml-3', (([
                'scene1',
                'scene2',
                'scene3',
                'scene4',
                'scene5',
                'countdown',
                'activity1_1',
                'activity1_2',
                'activity1_3',
                'activity1_4',
                'activity1_5',
                'activity2_1',
                'activity2_2',
                'activity2_3',
                'activity2_4',
                'activity3_1',
                'activity3_2',
                'activity3_3',
                'activity4_1',
                'activity4_2',
                'activity4_3',
                'activity4_4',
                'activity5_1',
                'activity5_2',
                'activity5_3',
                'activity5_3',
                'textentry-activity',
                'order-activity',
                'checks-activity',
                'qanda-activity',
                'radio-activity',
                'end',
                'locked'].indexOf(_vm.$route.name) > -1) ? 'btn-nav':'btn-nav-red'), 'btn-nav-generic'],on:{"click":_vm.showHelpModal}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'question']}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }