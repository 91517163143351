<template>
    <div class="activity3_3checks w-100 h-100">
        <Checks 
            act_id="act_11"
            :scene_id="3"
            title="Actuación inicial"
            intro_text="Se solicita derivación hospitalaria en ambulancia medicalizada. En el tiempo de espera hasta la llegada de la ambulancia, ¿Qué actitud terapéutica debemos iniciar?" 
            question="Teniendo en cuenta sus constantes, ¿Qué intervención priorizarías?"             
            :options="[
                {
                    text: 'Ranitidina',
                    value: 0,
                },
                {
                    text: 'Nitroglicerina',
                    value: 1,
                },
                {
                    text: 'Oxigenoterapia',
                    value: 2,
                },
                {
                    text: 'Omeprazol',
                    value: 3,
                },
                {
                    text: 'Ácido acetil salicílico',
                    value: 4,
                },
                {
                    text: 'Clopidogrel',
                    value: 5,
                },
                {
                    text: 'Metamizol',
                    value: 6
                },
                {
                    text: 'Diazepam',
                    value: 7
                }
            ]" 
            :correct_answer="[
                1, 2, 4
            ]"
            clue_text="Realizaremos tres intervenciones de manera prioritaria"
            :feedback="feedback_content"
            incorrect_msg="La respuesta es incorrecta"
            :scenario_finisher="true"
            :next_scenario="4"
        ></Checks>
    </div>
</template>

<script>
import Checks from '../activitytype/Checks';
import Vue from 'vue';
import FeedbackEsc3Act3 from '../feedbacks/FeedbackEsc3Act3';

export default {
    components: {
        Checks
    },
    data:function(){
        return {            
            feedback_content: ""
        };
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "scene3");
        vueapp.$store.commit("setLastURL", "activity3_3");
        setActivityVisited('scene3', 'act_11');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackEsc3Act3)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;
    }
}
</script>