
//console.log = function() {}

import 'babel-polyfill'

const GAME_TIME = 45;
const MAX_GIVEN_SCORE = 200;

window['GAME_TIME'] = GAME_TIME;
window['MAX_GIVEN_SCORE'] = MAX_GIVEN_SCORE;

import cssVars from 'css-vars-ponyfill';

cssVars({
    // Only styles from CodePen's CSS panel
    include: 'style:not([data-ignore])',
    // Treat all browsers as legacy
    onlyLegacy: false,
    
    // DEMO: Toggles to see results
    // ----------------------------
    // preserveStatic: false,
    // preserveVars: true,
    // updateURLs: false,
    // variables: { '--color': 'purple' },
    // ----------------------------
});

import Vue from 'vue'
import BootstrapVue from "bootstrap-vue"
import App from './App.vue'
import dispatcher from './clientdispatcher.js'
import VueSession from 'vue-session'
import store from './store/store.js'
import router from './router'

import moment from 'moment'

import dotenv from 'dotenv'
dotenv.config()

Vue.prototype.moment = moment

//Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExpand, faCompress, faQuestion } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faExpand, faCompress, faQuestion)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

import "animate.css"

//jQuery
window.$ = window.jQuery = require('jquery')
window.isEmptyArray = function(emptyArray) {
        return (Array.isArray(emptyArray) && emptyArray.length === 0)
    }
    //Loading plugin
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

import VueCountdownTimer from 'vuejs-countdown-timer';
Vue.use(VueCountdownTimer);

// Init plugin
Vue.use(Loading);

//Vuex
Vue.use(store);

//moment.js
Vue.use(require('vue-moment'));

//Date time picker
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
Vue.use(datePicker);

Vue.use(BootstrapVue)
Vue.use(VueSession, { persist: true });

Vue.config.productionTip = false

Vue.prototype.$dispatcher = dispatcher;

window['countdown_initial'] = process.env.VUE_APP_COUNTDOWN_INITIAL;
window['game_id'] = process.env.VUE_APP_GAME_ID;
window['vueapp'] = new Vue({
    router,
    store,
    render: h => h(App),
    components: {
        Loading
    },
    data: function() {
        return {
            visible: false,
            loader: null,
        };
    },
    methods: {
        open() {
            this.loader = this.$loading.show({
                loader: 'dots'
            });
        },
        close() {
            setTimeout(() => {
                    this.loader.hide();
                    game.adjustGame();
                }
                , 1000)
        },
    },
	beforeCreate() {
		this.$store.commit('initialiseStore');
    },
    created() {
        if (performance.navigation.type == 1) {
            if(this.$route.path != '') {
                this.$router.push({path: '/'})
            }       
        }
    }
}).$mount('#app')

//router global variable.
window['router'] = router;
window['game_time'] = new Date().getTime() + (60000*process.env.VUE_APP_COUNTDOWN_MINUTES);
window['handleUser'] = function(){
    if(vueapp.$store.state.game_state == null){
        router.push({name:"start"}).catch((e)=>{console.log(e)});
    }else{
        router.push({name:vueapp.$store.state.last_url}).catch((e)=>{console.log(e)});
    }
}

window['startCountDown'] = function(){    
    vueapp.$store.commit("setFullScreenMessage", false);
    $("#countdown").fadeIn(); 
    vueapp.$store.commit("setGameState", "starting");
    vueapp.$store.commit("setLastURL", "countdown");
    var countdown_timer = setInterval(function() {             
        vueapp.$store.state.beep.pause();            
        vueapp.$store.state.beep.play();        
        vueapp.$store.commit('setCountDown', vueapp.$store.state.countdown - 1);
        if(vueapp.$store.state.countdown == 0 || vueapp.$store.state.countdown < 0) {
            clearInterval(countdown_timer);
            if(vueapp.$store.state.game_state == "starting"){
                router.push({name:"map"}).catch((e)=>{console.log(e)});
                vueapp.$store.commit("setTimer", new Date().getTime() + (60000*process.env.VUE_APP_COUNTDOWN_MINUTES));
            }
        }
    }.bind(this), 1000);
    vueapp.$store.commit("setCountDownInterval", countdown_timer);
}

window['resetDemo'] = function(){
    localStorage.clear();
    vueapp.$store.commit('resetGlobalState');
    router.push({name:"start"}).catch((e)=>{console.log(e)});
    clearInterval(window.countdown_interval);
    clearInterval(window.timer_interval);
}

window['getActivityStatus'] = function(scene_id, act_id){
    var found_activity = vueapp.$store.state.scenes["scene"+scene_id].activities[act_id];
    if(found_activity)
        return found_activity;
    
    return null;    
}

window['setActivityStatus'] = function(scene_id, act_id, data){
    var found_activity = vueapp.$store.state.scenes["scene"+scene_id].activities[act_id];
    if(found_activity){
        vueapp.$store.state.scenes["scene"+scene_id].activities[act_id].data = data;
        vueapp.$store.commit("setScenes", vueapp.$store.state.scenes);
    }
}

window['setActivityVisited'] = function(scene_id, act_id){
    var found_activity = vueapp.$store.state.scenes[scene_id].activities[act_id];
    if(found_activity){
        vueapp.$store.state.scenes[scene_id].activities[act_id].visited = true;        
        vueapp.$store.commit("setScenes", vueapp.$store.state.scenes);
    }
}

window['setSceneLocked'] = function(scene_id, locked){
    var found_scene = vueapp.$store.state.scenes[scene_id];
    if(found_scene){
        vueapp.$store.state.scenes[scene_id].locked = locked;        
        vueapp.$store.commit("setScenes", vueapp.$store.state.scenes);
    }
}

window['setActivityLocked'] = function(scene_id, act_id, locked){
    var found_activity = vueapp.$store.state.scenes[scene_id].activities[act_id];
    if(found_activity){
        vueapp.$store.state.scenes[scene_id].activities[act_id].locked = locked;        
        vueapp.$store.commit("setScenes", vueapp.$store.state.scenes);
    }
}

window['setActivityCompleted'] = function(scene_id, act_id, completed){
    var found_activity = vueapp.$store.state.scenes[scene_id].activities[act_id];
    if(found_activity){
        vueapp.$store.state.scenes[scene_id].activities[act_id].completed = completed;        
        vueapp.$store.commit("setScenes", vueapp.$store.state.scenes);
    }
}

window['setSceneCompleted'] = function(scene_id, completed){
    var found_scene = vueapp.$store.state.scenes[scene_id];
    if(found_scene){
        vueapp.$store.state.scenes[scene_id].completed = completed;        
        vueapp.$store.commit("setScenes", vueapp.$store.state.scenes);
    }
}

//Function for compare array items
window['compareArrays'] = function(arr, target) { 
    return (JSON.stringify(target) === JSON.stringify(arr));
};

//Function for compare array items for order activity
window['compareOrderArrays'] = function(arr, target) {
    var new_array = [];
    var has_nulls = false;
    for(var i = 0; i < arr.length; i++){
        new_array.push(arr[i].val);
    }

    for(var j = 0; j < target.length; j++){
        if(has_nulls == false && target[j] == null){
            has_nulls = true;
        }
    }
        
    if(has_nulls == true){
        
    }
    return (JSON.stringify(target) === JSON.stringify(new_array));
};

window['isGameFinished'] = function() {
    return (new Date().getTime() > game_time);
};

window['finishGame'] = function(){
    //alert("finishhhhh")
    //game_time
    var time_now = new Date().getTime();
    vueapp.$store.commit("setTimer", null)
    router.push({name:'end'})
}

window['assets'] = [];

handleUser();
