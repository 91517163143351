<template>
    <div class="activity1_1 w-100 h-100 d-flex justify-content-center align-items-center animate__animated animate__fadeIn">
        <div class="row activity-container w-100 h-100 d-flex justify-content-center align-items-center">
            <img id="esc_4_act_1" class="p-5" src="@/assets/ECG_E4_A1.png" alt="esc_4_act_1" rel="preload">
            <div class="w-100 d-flex justify-content-end align-items-center feeback-back-btn mr-5">
                <button class="btn btn-action mt-3 mb-3 rounded check-btn mr-5" onclick="router.push({name:'scene4'});">Volver</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "act_12",
    mounted: function() {
        vueapp.$store.commit("setBackURL", "scene4");
        vueapp.$store.commit("setLastURL", "activity4_1");
        setActivityVisited('scene4', 'act_12');
        var act = getActivityStatus(4, 'act_12');
        if(act.completed == false)
            vueapp.$store.commit("setScore", vueapp.$store.state.score + 1);
        setActivityCompleted('scene4', 'act_12', true);
    }
}
</script>

<style scoped>
    #esc_4_act_1{
        max-width: 100%;
        max-height: 100%;
    }
</style>