var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[(([
    'activity1_1',
    'activity1_2',
    'activity1_3',
    'activity1_4',
    'activity1_5',
    'activity2_1',
    'activity2_2',
    'activity2_3',
    'activity2_4',
    'activity3_1',
    'activity3_2',
    'activity3_3',
    'activity4_1',
    'activity4_2',
    'activity4_3',
    'activity4_4',
    'activity5_1',
    'activity5_2',
    'activity5_3',
    'activity5_3'].indexOf(_vm.$route.name) > -1) ? 'bg-feedback':'')],style:(_vm.cssProps)},[_c('div',{staticClass:"h-100 w-100"},[_c('router-view')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }