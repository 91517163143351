<template>
    <div id="lobby" ref="lobby" class="bg-countdown d-flex justify-content-center align-items-center">
        <img src="@/assets/BG_inicio.png" class="bg-inicio">
        <div class="w-100 h-100 d-flex justify-content-center align-items-center" id="loading">
            
            <h3 id="lobby-text">Espere mientras el juego comience...</h3>
            <div class="lds-ripple">
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Lobby',
        mounted: function(){
            //Play lobby sound
            //this.$refs.sound_lobby_player.play();  
            //Create a listener when the lobby sound ends, re-play again.          
            /*this.$refs.sound_lobby_player.addEventListener("ended", function(){
                this.$refs.sound_lobby_player.play();
            }.bind(this), true);*/
            //vueapp.$store.commit('setMusicPlayer', vueapp.$store.state.countdown_player);
            //Adjust game container
            this.adjustGame();
            /*fireNetworkAction('save-user-game-status', { 
                user_id: this.$session.get('user_id'), 
                game_id: vueapp.$store.state.user.game.id,
                current_scene: 'lobby', 
                current_activity: '', 
                score: 0
            });*/            
            game.height100(this.$refs.lobby, function(){});
        },
        created: function(){
            this.adjustGame();
        },
        methods: {            
            adjustGame: function(){                
                //Function for adjust the game container
                var nav = document.getElementsByClassName("navbar_component");
                //console.log(nav)
                var messages = document.getElementsByClassName("messages_component");
                //console.log(messages)
                var game_container = document.getElementsByClassName("game_component");
                //console.log(game)
                
                if(nav && messages && game_container){
                    //console.log(nav[0].offsetHeight)
                    //  console.log(messages[0].offsetHeight)
                    if(game_container[0])
                        game_container[0].style.height = (window.innerHeight - (nav[0].offsetHeight + messages[0].offsetHeight) ) + "px"; 
                }
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
