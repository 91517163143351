var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('QandA',{attrs:{"questions":[
    {
        id: 0,
        text: 'Pregunta 1',
    },
    {
        id: 1,
        text: 'Pregunta 2',
    },
    {
        id: 2,
        text: 'Pregunta 3',
    },
    {
        id: 3,
        text: 'Pregunta 4',
    },
    {
        id: 4,
        text: 'Pregunta 5',
    }
],"answers":[
    'Respuesta 1',
    'Respuesta 2',
    'Respuesta 3',
    'Respuesta 4',
    'Respuesta 5' ]}})}
var staticRenderFns = []

export { render, staticRenderFns }