var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Radio',{attrs:{"act_id":"act_15","scene_id":4,"title":"La glucemia en su sitio","intro_text":"La glucemia tanto baja como alta, son  importantes","question":"¿Qué actitud deberíamos tomar con respecto a la glucemia?","options":[
        {
            id: 0,
            name: 'respuesta1',
            text: 'Bajarla por debajo de 140 mg/dl.',
            value: 0,
        },
        {
            id: 1,
            name: 'respuesta2',
            text: 'Infundir glucosado hasta subirla por encima de 180 mg/dl.',
            value: 1,
        },
        {
            id: 2,
            name: 'respuesta3',
            text: 'Dejarla igual porque está bien controlada.',
            value: 2,
        },
        {
            id: 3,
            name: 'respuesta4',
            text: 'Dejarla igual pero vigilarla porque si sube habría que corregirla.',
            value: 3,
        }
    ],"correct_answer":3,"incorrect_msg":"La respuesta es incorrecta","clue_text":"La glucemia varía cada minuto","feedback":_vm.feedback_content,"scenario_finisher":true,"next_scenario":5}})}
var staticRenderFns = []

export { render, staticRenderFns }