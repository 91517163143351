<template>
    <div class="progress w-100 h-100 p-3">
        <div class="row w-100 h-100">
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Loading',
        data(){
            return {
                max_images: 100,
                progress: 0,
                image_log: [],
                images : {}
            };
        },
        mounted: function(){
            /*this.max_images = Object.keys(this.images).length;
            window.assets = this.images;
            console.log(this.max_images)

            for (var prop in this.images) {
                this.getImage(this.images[prop], function(image){
                    console.log(image)
                    this.progress = this.progress + 1;
                    console.log(this.progress)
                    if(this.progress == this.max_images){
                        console.log("finished")
                        
                        //vueapp.$store.commit("setImageAssets", window.assets);
                        router.push("start");
                    }
                }.bind(this));
            }*/

        },
        methods:{
            getImage(image, callback){
                var xhr = new XMLHttpRequest();
                xhr.open('get', image.src);
                image.img = new Image();
                image.img.src = image.src;
                xhr.responseType = 'blob';
                xhr.onload = function(){
                    var fr = new FileReader();                    
                    fr.onload = function(){
                        image.data = this.result;
                        callback(image);
                    };                    
                    fr.readAsDataURL(xhr.response);
                };                
                xhr.send();
            }
        }
    }
</script>
