<template>
    <div class="activity5_1 w-100 h-100 d-flex justify-content-center align-items-center animate__animated animate__fadeIn">
        <div class="row activity-container w-100 h-100 d-flex justify-content-center align-items-center">
            <img id="esc_5_act_1" class="p-5" src="@/assets/esc_5_act_1.png" alt="esc_5_act_1" rel="preload">
            <div class="w-100 d-flex justify-content-end align-items-center feeback-back-btn mr-5">
                <button class="btn btn-action mt-3 mb-3 rounded check-btn mr-5" onclick="router.push({name:'scene5'});">Volver</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "act_16",
    mounted: function() {
        vueapp.$store.commit("setBackURL", "scene5");
        vueapp.$store.commit("setLastURL", "activity5_1");
        setActivityVisited('scene5', 'act_16');
        var act = getActivityStatus(5, 'act_16');
        if(act.completed == false)
            vueapp.$store.commit("setScore", vueapp.$store.state.score + 1);
        setActivityCompleted('scene5', 'act_16', true);
    }
}
</script>

<style scoped>
    #esc_5_act_1{
        max-width: 100%;
        max-height: 100%;
    }
</style>