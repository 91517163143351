<template>
    <div class="w-100 h-100 animate__animated animate__fadeIn">
        <div class="row w-100 h-100 m-0" v-if="(activity_response==null || activity_response ==false)">
            <div :class="left_size">
                <div class="activity_title  question-title text-rockwell">{{ title }}</div>
                <div class="intro_text ml-5 intro_text_order text-oswald" v-html="intro_text"></div>
                <p class="ml-5  intro_text_order order-warning text-center text-information-order mb-3"><em>Arrastra y suelta los elementos de la segunda columna para ordenar las frases</em></p>
                <p class="ml-5  intro_text_order order-warning text-center text-information-order"><em>¡Ten cuidado! al arrastrar un elemento a una posición los demás se desplazan. Debes asignarlos en orden.</em></p>

                <div v-if="!clue && clue_text != null" class="d-flex justify-content-center align-items-center flex-column  intro_text_order ml-5 text-rockwell">
                    <button
                        :disabled="(current_date.diff(last_clue_date, 'seconds') < clue_time)" 
                        class="rounded pt-2 pb-2 pr-5 pl-5 clues clue-button" 
                        v-on:click="getClue">
                        ¡PISTA!
                    </button>
                </div>
                <div class="row text-rockwell" v-if="clue && clue_text != null">
                    <div class="rounded p-2 w-100 clues clue-show justify-content-center align-items-center  intro_text_order ml-5 text-rockwell">
                        ¡PISTA!
                        <div class="text-oswald" v-html="clue_text"></div>
                    </div>
                </div>
                <p class="ml-5 intro_text_order order-warning text-center text-information-order clue-msg"  v-if="!clue && (current_date.diff(last_clue_date, 'seconds') < clue_time) && clue_text != null">Hace muy poco que pediste una pista, inténtalo más tarde</p>
            </div>
            <div :class="right_size">
                <div class="row m-0">
                    <h3 class="text-rockwell w-100 question-title">{{ question }}</h3>
                    <div :class="left_column">
                        <div v-if="enunciados.length > 0" class="order">
                            <ul class="list-group">
                                <li class="list-group-item order-text-li" :style="row_style" v-for="enunciado in enunciados" :key="enunciado.id"><span class="order-text">{{ enunciado.content }}</span></li>
                            </ul>
                        </div>
                    </div>
                    <div :class="right_column">
                        <div class="order">
                            <draggable v-model="dragmodel" group="orderactivity" @start="drag=true" @end="onEnd" class="list-group drag-item" ghost-class="ghost" v-bind:options='options_bind'>
                                <li class="list-group-item  w-100  order-text-li" v-if="element.name != null" :style="row_style" :class="element.cssclass" v-for="element in dragmodel" :key="element.id"><span class="order-text">{{ element.name }}</span></li>
                            </draggable>
                        </div>
                    </div>  
                </div>
                <div class="row m-0 d-flex justify-content-center align-items-center">
                    <button v-if="activity_response == null" class="btn btn-action mt-3 mb-3 rounded check-btn" v-on:click="check_activity">Comprobar</button>
                    <div v-if="activity_response==false" class="incorrectmsg p-2 mt-3 result text-center rounded">
                        {{ incorrect_msg }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="activity_response==true" class="w-100 h-100 d-flex justify-content-center flex-column">
            <div class="w-100 h-100" v-html="feedback"></div>
            <div class="w-100 d-flex justify-content-center align-items-center scene-finisher-btn">
                <button v-if="scenario_finisher == true" @click="$router.push({name:'map'})" class="btn btn-action mt-3 mb-3 rounded check-btn">Volver a la pantalla principal</button>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import ActivityType from './ActivityType'

export default {
    extends: ActivityType,
    props: {
        act_id: 0,
        scene_id: 0,
        options: {
            type: Array,
            default: []
        },
        correct_order: {
            type: Array,
            default: []
        },
        enunciados: {
            type: Array,
            default: []
        },
        left_size: {
            type: String,
            default: 'col-6'
        },
        right_size: {
            type: String,
            default: 'col-6'
        },
        left_column: {
            type: String,
            default: 'col-6'
        },
        right_column: {
            type: String,
            default: 'col-6'
        },
        scenario_finisher: {
            type: Boolean,
            default: false,
        },
        next_scenario: {
            type: Number,
            default: 0,
        },
        row_style:{
            type: String,
            required: false,
            default: "60px",
        },
        final_activity: {
            type:Boolean,
            default: false,
            required: false,
        },
    },
    data: function() {
        return {
            isCorrect: null,
            dragmodel: [],
            result: '',
            answer: null,
            answer_render: null,
            activity_response: null,
        }
    },
    components: {
        draggable
    },
    mounted: function() {
        if (isEmptyArray(this.dragmodel))
            this.dragmodel = this.options;

        var act = null;
        act = getActivityStatus(this.scene_id, this.act_id);

        if(act != null){
            if(act.data != null){
                this.activity_response = act.data.activity_response;
                this.can_clue = act.data.can_clue;
                this.checked_options = act.data.checked_options;
                this.clue = act.data.clue;
                this.current_date = act.data.current_date;
                this.is_correct = act.data.is_correct;
                this.last_clue_date = act.data.last_clue_date;
                this.render = act.data.render;
                if (isEmptyArray(this.dragmodel))
                    this.dragmodel = act.data.options;
            }
        }
    },
    computed: {
        options_bind() {
            return {
                group: 'menu',
                animation: 200
            }
        }
    },
    methods: {
        onEnd: function(){
            setActivityStatus(this.scene_id,this.act_id, this.$data);
        },
        check_activity: function() {            
            if(compareOrderArrays(this.dragmodel,this.correct_order)){
                this.activity_response = true;
                this.is_correct = true;
                setActivityStatus(this.scene_id, this.act_id, this.$data);
                var act = getActivityStatus(this.scene_id, this.act_id);
                if(act.completed == false)
                    vueapp.$store.commit("setScore", vueapp.$store.state.score + 1);
                setActivityCompleted("scene"+this.scene_id, this.act_id, true);
                if(this.scenario_finisher == true){
                    setSceneLocked("scene"+this.next_scenario, false);
                    setSceneCompleted("scene"+(this.next_scenario-1), true);
                }
                if(this.$store.state.correct != null)
                    this.$store.state.correct.play();
            }else{
                this.activity_response = false;
                this.is_correct = false;
                setTimeout(function(){
                    this.activity_response = null;
                    this.is_correct = null;
                }.bind(this), 3000);
                if(this.$store.state.error != null)
                    this.$store.state.error.play();
            }
        }
    }
}
</script>

<style scoped>

@media (min-width: 768px) { 
    .list-group-item{
        font-size: 12px;
        height: 50px;
    }
}

@media (min-width: 1200px) { 
    .list-group-item{
        font-size: 14px;
        height: 50px;
    }
}
 
@media (min-width: 1024px) { 
    .list-group-item{
        font-size: 15px;
        height: 70px;
    }
}
</style>
