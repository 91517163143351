<template>
    <div id="map" class="row h-100 w-100 m-0 p-3 animate__animated animate__fadeIn">

        <b-modal size="xl" id="modal-help" ref="modal-help" centered hide-footer title-html="<span class='text-white text-rockwell'>Ayuda</span>">
            <help></help>
        </b-modal>
        <div class="h-100 w-100 d-flex justify-content-center align-items-center" id="map-container" ref="map-container">
            <mapasvg id="mapsvg" ref="mapsvg"/>
        </div>
    </div>
</template>

<script>
    import mapasvg from '@/assets/mapa.svg'
    import Help from '../views/Help'
    export default {
        name: 'Map',
        components: {
            mapasvg, Help
        },
        destroyed() {
            //Clean up click events for each scene.
            var i = 0;
            for (var prop in vueapp.$store.state.scenes) {
                if(vueapp.$store.state.scenes[prop].locked == false){
                    //Delete path click event.
                    $(vueapp.$store.state.scenes[prop].scene).off("click", "**");
                    //Delete ripple click event.
                    $('#ripple_'+(i+1)).off("click", "**");
                }
                i++;
            }
        },
        methods: {
            showHelpModal(){
                this.$refs['modal-help'].show();
            },
            hideHelpModal(){
                this.$refs['modal-help'].hide();
            }
        },
        mounted: function(){
            //window.onbeforeunload = function()
            //{
            //    console.log("aaaa")
            //}
            //Save game state.
            vueapp.$store.commit("setGameState", "started");
            //Save the last url that the user has visited.
            vueapp.$store.commit("setLastURL", "map");
            //Get the SVG scenes.
            var scenes = $('[data-class="grupo"]').toArray();
            //If storage scenes are empty. Then fill them for the first time
            if(Object.keys(vueapp.$store.state.scenes).length === 0){
                //Lock each scene.
                for(var i = 0; i < scenes.length; i++){
                    //Configure each scene and push scene to the array of scenes.
                    vueapp.$store.state.scenes[scenes[i].id] = {
                        scene: scenes[i],
                        activities: {},
                        locked: (i == 0) ? false : true,
                        completed: false,
                    };
                }
                //Save the scenes information into vuex (localStorage).
                vueapp.$store.commit("setScenes", vueapp.$store.state.scenes);
            }else{
                //Update each scene path
                for(var i = 0; i < scenes.length; i++){
                    //Configure each scene and push scene to the array of scenes.
                    vueapp.$store.state.scenes[scenes[i].id].scene = scenes[i];
                }
                //Save the scenes information into vuex (localStorage).
                vueapp.$store.commit("setScenes", vueapp.$store.state.scenes);
            }

            if(Object.keys(vueapp.$store.state.scenes).length > 0){
                //Loop each scene.
                var i = 0;
                for (var prop in vueapp.$store.state.scenes) {
                    if(vueapp.$store.state.scenes[prop].locked == false){
                        //if is not locked then we allow the click event and hide the scene background and the locker.
                        $(vueapp.$store.state.scenes[prop].scene).click(function(){
                            router.push({name:this.id});
                        }).attr('fill', 'rgba(0,0,0,0)');
                        $("#locker"+(i+1)).click(function(scene){
                            router.push({name:scene.id});
                        }.bind(this, vueapp.$store.state.scenes[prop].scene)).attr('fill', 'rgba(0,0,0,0)');
                    }
                    if(vueapp.$store.state.scenes[prop].completed == true){
                        $("#"+prop+"_tick").click(function(scene){
                            router.push({name:scene.id});
                        }.bind(this, vueapp.$store.state.scenes[prop].scene)).attr('fill', 'rgb(34 193 0)');                        
                    }
                    i++;
                }
            }

            if($('.helpbtn')){
                $('.helpbtn').click(function(){
                    this.showHelpModal()
                }.bind(this));
            }
        }
    }
</script>

<style scoped>
    #map{
        background-color: #c41f39;
    }
    #map-container{
        position: relative;
    }
    #mapsvg{
        position: absolute;
        max-width: 100%;
        max-height: 100%;
    }
    /*#mapbg{
        position: absolute;
        max-width: 100%;
        max-height: 100%;
    }*/
</style>
