var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Radio',{attrs:{"act_id":"act_05","scene_id":1,"title":"Control farmacológico de los factores de riesgo cardiovascular (FRCV)","intro_text":"Conocemos el tratamiento actual de Manu...","question":"¿Qué modificarías del tratamiento?","options":[
        {
            id: 0,
            name: 'respuesta1',
            text: 'Cambiar fármaco glibenclamida por iSGLT-2, cambiar enalapril 20 por ramipril 10 y cambiar simvastatina a 10 mg',
            value: 0,
        },
        {
            id: 1,
            name: 'respuesta2',
            text: 'Cambiar glibenclamida por semaglutida semanal, dejar enalapril e hCTZ igual, cambiar simvastatina de 20 mg por atorvastatina de 20 mg',
            value: 1,
        },
        {
            id: 2,
            name: 'respuesta3',
            text: 'Cambiar glibenclamida y metformina por insulina, dejar enalapril e hCTZ igual, cambiar simvastatina de 20 mg por atorvastatina de 20 mg',
            value: 2,
        },
        {
            id: 3,
            name: 'respuesta4',
            text: 'Añadir un iDPP-4',
            value: 3,
        }
    ],"correct_answer":1,"incorrect_msg":"La respuesta es incorrecta","clue_text":"Algunos fármacos tienen efectos secundarios","feedback":_vm.feedback_content,"scenario_finisher":true,"next_scenario":2}})}
var staticRenderFns = []

export { render, staticRenderFns }