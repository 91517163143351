var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activity3_2checks w-100 h-100"},[_c('Checks',{attrs:{"act_id":"act_10","scene_id":3,"title":"Recepción del paciente en zona de Urgencia y paso a box","intro_text":"Se debe realizar una toma de constantes inicial","question":"¿Qué constantes deben priorizarse?","options":[
            {
                text: 'Glucemia',
                value: 0,
            },
            {
                text: 'Temperatura',
                value: 1,
            },
            {
                text: 'Combur test',
                value: 2,
            },
            {
                text: 'Tensión arterial',
                value: 3,
            },
            {
                text: 'Saturación O2',
                value: 4,
            },
            {
                text: 'Pulsos pedios',
                value: 5,
            },
            {
                text: 'Frecuencia cardiaca',
                value: 6
            },
            {
                text: 'Posición 45º',
                value: 7
            }
        ],"correct_answer":[
            0, 3, 4, 6
        ],"clue_text":"Elegir 4 opciones","feedback":_vm.feedback_content,"incorrect_msg":"La respuesta es incorrecta"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }