var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Radio',{attrs:{"act_id":"act_04","scene_id":1,"title":"Buscando un objetivo","intro_text":"¿Cuál sería el objetivo de control en este paciente?","question":"El objetivo de control en este paciente sería de:","options":[
        {
            id: 0,
            name: 'respuesta1',
            text: 'Hb A1c de 6.5%',
            value: 0,
        },
        {
            id: 1,
            name: 'respuesta2',
            text: '7%',
            value: 1,
        },
        {
            id: 2,
            name: 'respuesta3',
            text: '< 8%',
            value: 2,
        },
        {
            id: 3,
            name: 'respuesta4',
            text: 'Tan cerca de lo normal como sea posible sin efectos indeseables',
            value: 3,
        }
    ],"correct_answer":3,"incorrect_msg":"La respuesta es incorrecta","clue_text":"Aunque al mirar las tablas vemos números, la lógica y la evidencia nos dicen otra distinta…","feedback":_vm.feedback_content}})}
var staticRenderFns = []

export { render, staticRenderFns }