<template>
    <Radio 
        act_id="act_05"
        :scene_id="1"
        title="Control farmacológico de los factores de riesgo cardiovascular (FRCV)"
        intro_text="Conocemos el tratamiento actual de Manu..."
        question="¿Qué modificarías del tratamiento?"
        :options="[
            {
                id: 0,
                name: 'respuesta1',
                text: 'Cambiar fármaco glibenclamida por iSGLT-2, cambiar enalapril 20 por ramipril 10 y cambiar simvastatina a 10 mg',
                value: 0,
            },
            {
                id: 1,
                name: 'respuesta2',
                text: 'Cambiar glibenclamida por semaglutida semanal, dejar enalapril e hCTZ igual, cambiar simvastatina de 20 mg por atorvastatina de 20 mg',
                value: 1,
            },
            {
                id: 2,
                name: 'respuesta3',
                text: 'Cambiar glibenclamida y metformina por insulina, dejar enalapril e hCTZ igual, cambiar simvastatina de 20 mg por atorvastatina de 20 mg',
                value: 2,
            },
            {
                id: 3,
                name: 'respuesta4',
                text: 'Añadir un iDPP-4',
                value: 3,
            }
        ]" 
        :correct_answer=1
        incorrect_msg="La respuesta es incorrecta"
        clue_text="Algunos fármacos tienen efectos secundarios"
        :feedback="feedback_content"
        :scenario_finisher="true"
        :next_scenario="2">
    </Radio>
</template>

<script>
import Radio from '../activitytype/Radio';
import Vue from 'vue';
import FeedbackEsc1Act5 from '../feedbacks/FeedbackEsc1Act5';

export default {
    data: function(){
        return {
            feedback_content: ""
        };
    },
    components: {
        Radio
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "scene1");
        vueapp.$store.commit("setLastURL", "activity1_5");
        setActivityVisited('scene1', 'act_05');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackEsc1Act5)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;
    }
}
</script>