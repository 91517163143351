<template>
    <Radio 
        act_id="act_04"
        :scene_id="1"
        title="Buscando un objetivo"
        intro_text="¿Cuál sería el objetivo de control en este paciente?"
        question="El objetivo de control en este paciente sería de:"
        :options="[
            {
                id: 0,
                name: 'respuesta1',
                text: 'Hb A1c de 6.5%',
                value: 0,
            },
            {
                id: 1,
                name: 'respuesta2',
                text: '7%',
                value: 1,
            },
            {
                id: 2,
                name: 'respuesta3',
                text: '< 8%',
                value: 2,
            },
            {
                id: 3,
                name: 'respuesta4',
                text: 'Tan cerca de lo normal como sea posible sin efectos indeseables',
                value: 3,
            }
        ]" 
        :correct_answer=3
        incorrect_msg="La respuesta es incorrecta"
        clue_text="Aunque al mirar las tablas vemos números, la lógica y la evidencia nos dicen otra distinta…"
        :feedback="feedback_content">
    </Radio>
</template>

<script>
import Radio from '../activitytype/Radio';
import Vue from 'vue';
import FeedbackEsc1Act4 from '../feedbacks/FeedbackEsc1Act4';

export default {
    data: function(){
        return {
            feedback_content: ""
        };
    },
    components: {
        Radio
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "scene1");
        vueapp.$store.commit("setLastURL", "activity1_4");
        setActivityVisited('scene1', 'act_04');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackEsc1Act4)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;
    }
}
</script>