<template>
    <div class="order">
        <div class="introText">
            {{ introText }}
        </div>
        <label>¿Qué constantes quieres que tome tu compañera en primer lugar?</label>
        <div class="row">
            <div class="col-6">
                <div class="form-group" v-for="(element) in options" :key="element.id">
                    <input type="checkbox" :id="element.name" :value="element.id" v-model="checkedNames" />
                    <label :for="element.name">{{ element.text }}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <button v-on:click="checkSelection">Comprobar</button>
        </div>
        <div v-if="(answer != null)">
            <div v-if="answer">Correcto</div>
            <div v-if="!answer">Incorrecto</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        introText: '',
        options: {
            type: Array,
            required: true,
            default: []
        },
        correctAnswer: {
            type: Array,
            required: true,
            default: []
        },
        scenario_finisher: {
            type: Boolean,
            default: false,
        },
        next_scenario: {
            type: Number,
            default: 0,
        }
    },
    data: function() {
        return {
            checkedNames: [],
            answer: null,
        }
    },
    methods: {
        checkSelection: function() {
            valid = false;
            if (this.checkedNames.length != this.correctAnswer.length) {
                valid = false;
            } else {
                for (var i in this.checkedNames) {
                    var valid = false;
                    console.log('Comprobando: ' + this.checkedNames[i])
                    for (var j in this.correctAnswer) {
                        console.log('--- Con: ' + this.correctAnswer[j]);
                        if (this.checkedNames[i] == this.correctAnswer[j]) {
                            console.log('Localizado');
                            valid = true;
                            break;
                        }
                    }
                    if (!valid) {
                        valid = false; break;
                    }
                }
                console.log('Resultado final: ' + valid);
            }
            if (valid) {
                this.answer = true;
                if (this.scenario_finisher) {
                    fireNetworkAction("save-user-scene-status", {
                        user_id: this.$session.get('user_id'), 
                        game_id: vueapp.$store.state.user.game.id, 
                        scene_id: this.next_scenario,
                        status: 1
                    });
                }
            } else this.answer = false;
        }
    }
}
</script>