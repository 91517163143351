<template>
    <Order 
        :actid="actid"
        title="Escena final de prueba"
        broadcastMessage="ha acabado la actividad checksactivityscene2"
        question="Ardenalos alfabéticamente" 
        introText="Escena final de prueba" 
        :options="[
            { id: 1, name: 'Evidencia A', cssclass: 'opcion1', val: 1}, 
            { id: 2, name: 'Evidencia B', cssclass: 'opcion2', val: 2}, 
            { id: 3, name: 'Evidencia E', cssclass: 'opcion3', val: 3}, 
            { id: 4, name: 'Evidencia B', cssclass: 'opcion4', val: 2},
            { id: 5, name: 'Evidencia A', cssclass: 'opcion5', val: 1},
            { id: 6, name: 'Evidencia A', cssclass: 'opcion6', val: 1},
            { id: 7, name: 'Evidencia B', cssclass: 'opcion7', val: 2}
        ]" 
        :correctOrder="[1, 1, 1, 2, 2, 2, 3]"
        feedback="<ol>
                <li>ACERTADO</li>
            </ol>"
        incorrectMsg="La solución es incorrecta!"
        clueText="Te será de utilidad conocer que disponemos de monofilamento en el Centro de Salud."
        leftsize="col-10"
        rightsize="col-2"
        :enunciados="[
            {
                id: 1,
                content: '------------------------>'
            },
            {
                id: 2,
                content: '------------------------>'
            },
            {
                id: 3,
                content: '------------------------>'
            },
            {
                id: 4,
                content: '------------------------>'
            },
            {
                id: 5,
                content: '------------------------>'
            },
            {
                id: 6,
                content: '------------------------>'
            },
            {
                id: 7,
                content: '------------------------>'
            },
        ]"
        isEnd=true
        >
    </Order>
</template>

<script>
import Order from '../activitytype/Order';

export default {
    data() { 
        return {
            actid: 13,
            isEnd: true,
            broadcastMessage: '',
        }
    },
    components: {
        Order
    },
    mounted: function() {
        vueapp.$store.commit('setBackURL', '/scene2');
        fireNetworkAction('save-user-game-status', {
            user_id: this.$session.get('user_id'), 
            game_id: vueapp.$store.state.user.game.id,
            current_scene: 'scene2', 
            current_activity: 'escenario_2_02', 
            score: 0
        });
    }
}
</script>