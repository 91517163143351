var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Order',{attrs:{"actid":_vm.actid,"title":"Escena final de prueba","broadcastMessage":"ha acabado la actividad checksactivityscene2","question":"Ardenalos alfabéticamente","introText":"Escena final de prueba","options":[
        { id: 1, name: 'Evidencia A', cssclass: 'opcion1', val: 1}, 
        { id: 2, name: 'Evidencia B', cssclass: 'opcion2', val: 2}, 
        { id: 3, name: 'Evidencia E', cssclass: 'opcion3', val: 3}, 
        { id: 4, name: 'Evidencia B', cssclass: 'opcion4', val: 2},
        { id: 5, name: 'Evidencia A', cssclass: 'opcion5', val: 1},
        { id: 6, name: 'Evidencia A', cssclass: 'opcion6', val: 1},
        { id: 7, name: 'Evidencia B', cssclass: 'opcion7', val: 2}
    ],"correctOrder":[1, 1, 1, 2, 2, 2, 3],"feedback":"<ol>\n            <li>ACERTADO</li>\n        </ol>","incorrectMsg":"La solución es incorrecta!","clueText":"Te será de utilidad conocer que disponemos de monofilamento en el Centro de Salud.","leftsize":"col-10","rightsize":"col-2","enunciados":[
        {
            id: 1,
            content: '------------------------>'
        },
        {
            id: 2,
            content: '------------------------>'
        },
        {
            id: 3,
            content: '------------------------>'
        },
        {
            id: 4,
            content: '------------------------>'
        },
        {
            id: 5,
            content: '------------------------>'
        },
        {
            id: 6,
            content: '------------------------>'
        },
        {
            id: 7,
            content: '------------------------>'
        } ],"isEnd":"true"}})}
var staticRenderFns = []

export { render, staticRenderFns }