<template>
    <div class="scoreboard h-100 bg-scoreboard">
        <div class="row d-flex justify-content-center align-items-center h-100">
            <img src="@/assets/BG_Clasificacion.png">
            <div class="col-8 h-100 p-0">
                <h2 class="w-100 title-scene">Clasificación</h2>
                <ul class="list-group h-100 overflow-auto">
                    <b-list-group-item v-for="(user, index) in $store.state.scoreboard" :ref="'user_'+user.id" class="d-flex justify-content-between align-items-center mb-3 rounded-pill scoreboard-user" button>
                        <div class="d-flex justify-content-start align-items-center">
                            <span :class="['position rounded-circle p-2 mr-3 d-flex justify-content-center align-items-center scoreboard-li',getUserColor(user,index + 1 )]">
                                <span>{{ index + 1 }}º</span>
                            </span>
                            <img v-if="user.avatar" class="user-img rounded-circle image-user" v-bind:src="user.avatar">
                            <div v-else :class="['user_avatar_text rounded-circle d-flex justify-content-center align-items-center user-img', 'bg-img-'+getUserBackGround(user.id) ]"><div v-if="user.user">{{ user.user.charAt(0).toUpperCase() }}</div></div>
                            <span class="ml-3">{{ capitalize(user.user) }}</span>
                        </div>
                        <div class="d-flex justify-content-start align-items-center">
                            <span v-if="user.time_spent" class="mr-3 scoreboard-timeend">Ha finalizado en: <span class="badge badge-secondary">{{ Math.floor(user.time_spent / 60) }}</span> minutos <span class="badge badge-secondary">{{ user.time_spent % 60 }}</span> segundos</span>
                            <span v-else class="mr-3 scoreboard-timeend">No ha finalizado</span>
                            <span class="badge badge-primary badge-pill mr-3 scoreboard-score">{{ user.score }} puntos</span>                            
                        </div>
                    </b-list-group-item>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ScoreBoard',
        props:{
            id: Number
        },
        data: function(){
            return {
                users: []
            };
        },
        mounted: function() {
            /*var user = JSON.parse(JSON.stringify(vueapp.$store.state.user));
            user.status.current_scene = "scoreboard/"+vueapp.$store.state.user.game.id;
            user.status.current_activity = null;
            vueapp.$store.commit('setUser', user);*/
            
            console.log("GUARDADA LA RUTA SCORE BOARD")

            this.adjustGame();
            fireNetworkAction("scoreboard", this.id);
            
            vueapp.$store.commit('setBackURL', '/admin/game/'+this.id);
            fireNetworkAction('save-user-game-status', { 
                user_id: this.$session.get('user_id'), 
                game_id: vueapp.$store.state.user.game.id,
                current_scene: 'scoreboard/'+ this.id, 
                current_activity: null, 
                score: 0
            });
            //addNetworkListener('update-user', this.updateUserListener);

            game.height100(this.$refs.game_detail, function(){});
        },
        methods:{
            getUserColor: function(user, idx){
                var style_class = "scoreboard-li-";
                if(user.time_spent){
                    if(parseInt(idx) == "1")
                        return style_class + "gold";
                    if(parseInt(idx) == "2")
                        return style_class + "silver";
                    if(parseInt(idx) == "3")
                        return style_class + "bronze";                    
                }else{
                    return "scoreboard-li";
                }
            },
            capitalize: function(name){
                return name.charAt(0).toUpperCase() + name.slice(1);
            },
            getUserBackGround: function(id){
                return (id) ? id.toString()[id.toString().length - 1] : 0;
            },
            adjustGame: function(){                
                //Function for adjust the game container
                var nav = document.getElementsByClassName("navbar_component");
                //console.log(nav)
                var messages = document.getElementsByClassName("messages_component");
                //console.log(messages)
                var game_container = document.getElementsByClassName("game_component");
                //console.log(game)
                
                if(nav && messages && game_container){
                    //console.log(nav[0].offsetHeight)
                    //  console.log(messages[0].offsetHeight)
                    if(game_container[0])
                        game_container[0].style.height = (window.innerHeight - (nav[0].offsetHeight + messages[0].offsetHeight) ) + "px"; 
                }
            },
            addUser: function(user){
                this.users.push(user);
            },
            updateUser: function(user){
                var found = false;
                for(var i = 0; i < this.users.length && found == false; i++){
                    if(user.id == this.users[i].id){
                        found = true;
                        if(user.user)
                            this.users[i].user = user.user;
                        if(user.is_alive)
                            this.users[i].is_alive = user.is_alive;
                        if(user.is_logged)
                            this.users[i].is_logged = user.is_logged;
                        if(user.avatar)
                            this.users[i].avatar = user.avatar;
                    }
                }

                if(!found){
                    this.addUser(user);
                }
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
