module.exports = {
    adjustScreenSize : function(nav, game, window){
        var window_height = window.innerHeight;
        var nav_height = nav[0].offsetHeight;
        game[0].style.height = window_height - nav_height + "px";
    },
    toggleFullScreen : function() {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            return false;
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                return true;
            }
        }
    }
};