<template>
    <div id="app" ref="app" class="animate__animated animate__fadeIn">
        <div class="h-100">
            <!-- Navigation bar -->
            <navbar class="navbar_component"></navbar>
            <!-- Game content -->    
            <game id="game_component" ref="game_component" class="game_component"></game>
        </div>
        <div class="d-none">
            <audio id="beep" class="d-none">
                <source src="@/assets/sounds/beepcutted.mp3" type="audio/mp3">
                Your browser does not support the audio element.
            </audio>
            <audio id="end" class="d-none">
                <source src="@/assets/sounds/end.mp3" type="audio/mp3">
                Your browser does not support the audio element.
            </audio>
            <audio id="error" class="d-none">
                <source src="@/assets/sounds/error.mp3" type="audio/mp3">
                Your browser does not support the audio element.
            </audio>
            <audio id="correct" class="d-none">
                <source src="@/assets/sounds/correct.mp3" type="audio/mp3">
                Your browser does not support the audio element.
            </audio>
            <audio id="gameover" class="d-none">
                <source src="@/assets/sounds/game_over.mp3" type="audio/mp3">
                Your browser does not support the audio element.
            </audio>
        </div>
    </div>
</template>

<script>
    //Navigation bar
    import navbar from './components/NavBar.vue'
    import game from './components/Game.vue'
    import utils from './utils'

    export default {
        name: 'app',
        components: {
            navbar, game
        },
        destroyed() {
            window.removeEventListener("resize", this.adjustGameScreen);
        },
        mounted: function() {     
            utils.adjustScreenSize($('#navigationbar'), $('#game_component'), window);
            this.$store.commit("setBeepSound", $('#beep')[0]);
            this.$store.commit("setEndSound", $('#end')[0]);
            this.$store.commit("setErrorSound", $('#error')[0]);
            this.$store.commit("setCorrectSound", $('#correct')[0]);
            this.$store.commit("setGameOverSound", $('#gameover')[0]);
        },
        created: function() {
            window.addEventListener("resize", this.adjustGameScreen);
            setInterval(() => {
                this.$dispatcher.fireSignal('updateClues');
            }, 1000);
        },
        methods:{
            adjustGameScreen: function(){
                utils.adjustScreenSize($('#navigationbar'), $('#game_component'), window);
            },
        }
    }
</script>

<style data-ignore>
    :root {
        /* Navbar, clues, border inputs, feedback text */
        --ripple-color: red;
        --ripple-size: 25px;
        --ripple-border: 2px;
        --ripple-dark-color: rgb(0, 0, 0);
        --ripple-dark-size: 40px;
        --ripple-dark-border: 4px;
        --main-color: #ffffff;
        --text-color: rgb(255, 255, 255);
        --clock-color: rgb(242, 242, 242);
        --clock-text-color: rgb(213, 43, 43);
        --clock-face-color: rgb(242, 242, 242);
        --clock-back-color: rgb(218, 218, 218);
        --clue-color: rgb(136 139 180);
        --clue-border-color: rgb(136 139 180);
        --clue-bg-color: rgb(255, 255, 255);
        --activity-background: #e1e2ec;
        --activity-border: #888bb4;
        --activity-dialogue-background: #8f92b8;
        --btn-bg-color: rgb(136 139 180);
        --bg-main-color: #dc3545;
    }

    @font-face {
        font-family: Eurostile;
        src: url("/assets/fonts/EurostileLTStd-Bold.otf");
    }

    @font-face {
        font-family: 'Rockwell Nova';
        src: url("./assets/fonts/rockwell-nova/RockwellNova-Bold.ttf");
    }

    @font-face {
        font-family: 'Oswald Medium';
        src: url("./assets/fonts/oswald/Oswald-Medium.ttf");
    }

    @font-face {
        font-family: 'Oswald Light';
        src: url("./assets/fonts/oswald/Oswald-Light.ttf");
    }

    #intro-button{
        font-family: 'Oswald Medium';
        font-size: 2em;
    }

    #intro-case{
        font-family: 'Rockwell Nova';
        color: white;
        font-size: 40px;
    }

    #logo-long-color, #logo-long-blanco{
        height: 65%;
    }

    .bg-feedback{
        background-color: #e1e2ec !important;
    }

    .check-btn{
        font-family: 'Oswald Medium';
    }

    .modal-content .close{
        color: white;
    }

    .btn-escaperoom{
        background-color: var(--btn-bg-color) !important;
    }

    #rowtop-feedback-esc1act3{
        height:35%;
        position: relative;
        font-size: 18px;
        padding: 15px;
    }

    #start-background{
        background-color: var(--bg-main-color);
    }

    #rowbottom-feedback-esc1act3{
        height: 65%;
        position: relative;
        padding: 30px;
    }

    .feedback-big-text{
        font-family: 'Rockwell Nova';
        font-size: 30px;
    }

    .scene-finisher-btn{
        position: absolute;
        bottom: 20px;
    }

    .text-oswald{
        font-family: 'Oswald Medium';
    }

    .text-rockwell{
        font-family: 'Rockwell Nova';
    }

    .modal-content header{
        background-color: var(--bg-main-color)!important;
    }

    .iconhelp{
        max-width: 100%;
        max-height: 100%;
    }

    .vue-countdown-component .time-box {
        position: relative;
        box-sizing: border-box;
        font-weight: bold;
        height: 46px !important;
        min-width: 39px !important;
        font-size: 37px !important;
        text-align: center;
        -webkit-perspective: 60px;
        perspective: 78px;
        border-radius: 3px;
        padding: 0 10px;
        color: var(--clock-text-color) !important;
        line-height: 46px !important;
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .order-warning{
        color: var(--clue-color) !important;
        font-size: 15px;
    }

    .title-scene, .description-scene{
        margin-top: 3rem !important;
        margin-left: 3rem !important;
    }

    .title-scene{
        margin-top: 3rem !important;
        margin-left: 3rem !important;
    }

    .description-scene{
        margin-top: 3rem !important;
        margin-left: 3rem !important;
    }

    .time-box{
        background-color: var(--clock-color) !important;
    }

    .time-box > div.base .base-b{
        background-color: var(--clock-color) !important;
    }

    .time-box > div.face{
        background-color: var(--clock-face-color) !important;
    }

    .time-box > div.back{
        background-color: var(--clock-back-color) !important;
    }

    .time-text{
        font-size: 30px;
    }

    #btnstartgame{
        font-size: 30px;
    }

    .scene:hover, .activity:hover{
        cursor: pointer;
    }

    .logo-short{
        display:none;
    }   

    .activity-container{
        background-color: var(--activity-background) !important;
        font-size: 20px;
    }

    .activity-dialogue-container{
        background-color: var(--activity-dialogue-background) !important;
    }

    /* Image adjust */
    .feedback_image_adjust img.wide {
        max-width: 100%;
        max-height: 100%;
        height: auto;
    }
    .feedback_image_adjust img.tall {
        max-height: 100%;
        max-width: 100%;
        width: auto;
    }​

    /**ESTILOS JOTA2020 */

    input {
        padding: 10px;
        border-radius: 10px;
        border: 2px solid var(--main-color);
    }

    .feedback-area{
        width: 100%;
        height: 100%;
    }

    .feedback-area .colortext { 
        border-radius: 5px; 
        background-color: white;
        font-size: 1.7em;
        padding: 20px;
        margin: 0px;
    }

    .feedbackimg{
        width: 100%;
        height: 100%;
    }

    .feedback-area .feedbackimg { text-align: center; }

    .activity4_4radio .feedback-area img {
        width: 62% !important;
    }

    .drag-warn-text{
        color:#007bff;
    }

    /*#camera-bg{
        position: absolute;
        z-index: 999;
        top: 0px;
        left: 0px;
        background-color: rgba(255, 255, 255, 0.767);
        width: 100%;
        height: 100%;
    }

    #camera{
        position: relative;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
        width: 70%;
        height: 70%;
        background-color: white;
    }

    #camera-close{
        position: absolute;
        top: 10px;
        right: 10px;
    }*/

    /*
    .feedback-area .color1 { 
        color: #FFF; 
        font-weight: bold; 
        background-color: #ff8c00 !important;
        
    }

    .feedback-area .color2 { 
         background-color: #C0392B; 
         font-weight: bold; 
         color: #FFF; 
    }
    */

    .feedback-area .color1 {color: #ff9900; font-weight: bold }
    .feedback-area .color2 {color: #ff0000; font-weight: bold }
    .feedback-area .color3 { color: #00e600; font-weight: bold }
    .feedback-area .color4 { color: #663399; font-weight: bold }
    .feedback-area .color5 { color: #ff00ff; font-weight: bold }
    .feedback-area .color6 { color: #00ccff; font-weight: bold }

    .bg-color1 { background-color: #ff9900 !important; }
    .bg-color2 { background-color: #ff0000!important; }
    .bg-color3 { background-color: #00e600 !important; }
    .bg-color4 { background-color: #663399 !important; }
    .bg-color5 { background-color: #ff00ff !important; }
    .bg-color6 { background-color: #00ccff !important; }


    .introtext.text-white.mt-3 {
        font-size: 22px;
    }
    
    .buttoncheck h5 {
        font-size: 28px;
        color: #002855;
        padding: 5px 30px;
    }

    .entryPass.mt-3{
        color: #fff;
    }

    .botonespecial label.btn.btn-secondary {
        background: var(--btn-bg-color);
        font-size: 17px!important;
        margin-bottom: 11px!important;
        padding: 4px 25px 7px 25px!important;
        border-radius: 5px!important;
        border: none;
        font-family: 'Oswald Medium';
    }

    .text-information-order{
        font-size: 1.0rem !important;
    }

    .order-text-li{
        display: flex !important;
        justify-content: start !important;
        align-items: center !important;
    }

    .list-group{
        border-radius: 10px !important;
    }

    .order .list-group-item{
        margin-bottom: 3px;
        border-radius: 10px !important;
    }

    .escaperoom-main-bg{
        background-color: var(--bg-main-color) !important;
    }

    .introText {
        margin-bottom: 30px;
        font-size: 17px;
    }
    /**escenario pregunta imput */
    .question {
        margin-bottom: 10px;
        font-size: 31px;
    }
    .entryPass {
        margin-bottom: 40px;
    }
    .feedback.p-2.result.w-100.h-100.rounded {
        margin-bottom: 20px;
    }

    /**estilos citas */
    .answer {
        font-size: 35px;
        text-align: center;
        font-style: italic;
    }

    /**drag item */
    .drag-item li{
        font-size: 15px;
    }

    /***capa oculta y visible */
    .respuestaVisible{
        height: 57px;
    }
    .respuestaOculta{
        background-color: #fff9;
        position: relative;
        z-index: 99999;
        border: 1px solid #000;
        border-radius: 20px;
        padding: 25px 15px;
        font-size: 25px;
    }
    /**FIN ESTILOS JOTA2020 */


    .answerText{
        margin-top: 20px;
    }

    .correct{
        font-size: 45px;
        text-align: center;
        color: var(--main-color);
    }



    html, body{
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
        overflow: hidden;
        user-select: none;
    }

    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
        /*font-size: 35px;*/
    }

    /**
        main color
    */
    .main-color{
        color: var(--main-color);
    }
        
    /**
    * Navigation bar
    */
    #navigationbar{
        margin:0px;
        padding:0px;
        width:100%;
        background-color: var(--main-color);
        height: 100px;
        /*padding-right:40px !important;
        padding-left:40px !important;*/
    }

    .user-info{
        color: var(--text-color);
        font-weight: 700;
    }
    
    .sign-out{
        width: 20px;
        height: 20px;
    }
    
    .vld-background{
        
    }

    /* States */
    .dot-inactive {
        height: 30px;
        width: 30px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
    }
    .dot-active {
        height: 30px;
        width: 30px;
        background-color: rgb(32, 194, 0);
        border-radius: 50%;
        display: inline-block;
    }
    /**
     * Game
     */

     .game{
         font-size: 13px;
     }

    /**
     * Scenes
     */
    .scene-container{
        position: relative;
    }

    #scene1, #scene2, #scene3, #scene4, #scene5{
        position: absolute;
        top: 0px;
        left: 0px;
        width:100%;
        height: 100%;
    }

    .scenes{
        position: relative;
    }

    .bg-scenes{
        position: relative;
        width: auto;
        height: auto;
    }

    .scene-container img{
        /*height: 100%;*/
    }

    .scenes img{
        height: 100%;
    }

    #mapa-svg{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0px;
        left: 0px;
        /*height: 100%;
        width: 100%;*/
    }

    /**
     * Tooltip para la pista
     */
    .tooltip-wrapper {
        display: inline-block;
        margin: 50px;
    }

    .tooltip-wrapper .btn[disabled] {
        pointer-events: none;
    }

    .tooltip-wrapper.disabled {
        cursor: not-allowed;
    }

    /**
     * Button styles
     */
    .btn-action {
        background-color: #002854 !important;
    }

    .scoreboard-li-gold{
        background: gold !important;
        width: 60px;
        height: 60px;
        border: 3px solid grey;
    }

    .scoreboard-li-silver{
        background:silver !important;
        width: 60px;
        height: 60px;
        border: 3px solid grey;
    }

    .scoreboard-li-bronze{
        background: #cd7f32 !important;
        width: 60px;
        height: 60px;
        border: 3px solid grey;
    }

    .scoreboard-li{
        width: 60px;
        height: 60px;
        border: 3px solid grey;
    }

    .scoreboard-user{        
        background: #fbfbfb;
    }

    /**
     * messages
     */
    .messages_component{
        /*font-size:30px;*/
        z-index:888;
    }

    .clue-show {
        font-size: 19px;
        color: var(--clue-color) !important;
        border: 3px solid var(--clue-border-color) !important;
        border-color: var(--clue-border-color) !important;
    }
    
    /**
    * User image
    */
    .user-avatar, .user_avatar_text{
        width: 60px;
        height: 60px;
        border:5px solid rgba(255, 255, 255, 0.555);
    }

    .user_avatar_text{
        background: rgb(234, 234, 234);
        font-weight: bold;
        /*font-size: 40px;*/
        color: var(--text-color);
    }
    /**
     * Username
     */
    .username{
        font-size:35px;
        font-weight: bold !important;
        color: var(--text-color) !important;
    }


    @import url('https://fonts.googleapis.com/css?family=Teko&display=swap');

    .timer-text{
        color: var(--clock-color);
        font-weight: 700;
        /*font-size: 80px;*/
        font-family: 'Teko', sans-serif;
    }

    .timer path {
        fill: var(--clock-color);
    }

    /**
     * image-user
     */
    .image-user, .user_avatar_text{
        width: 60px;
        height: 60px;
    }

    .user-img{
        width: 50px;
        height: 50px;
    }
    
    .loading{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    /**
     * Countdown
     */

    .countdown{

    }

    #count_down span {
        opacity: 1;
        font-size: 500px;
        text-align: center;
    }

    .load {
        opacity: 0;

        -webkit-transition: opacity 0.8s ease-in;
        -moz-transition: opacity 0.8s ease-in;
        -ms-transition: opacity 0.8s ease-in;
        -o-transition: opacity 0.8s ease-in;
        transition: opacity 0.8s ease-in;
    }

    /*.zoom {
        color: white;
        position: relative;
        display: block;
    }
    .zoom::before {
        content: attr(data-text);
        color: white;
    }
    .zoom::after {
        content: '';
        position: absolute;
    }
    .zoom.flash::after {
        color: black;
        content: attr(data-text);
        transition: 1s ease;
        transform: scale(2);
        opacity: 0;
    }*/

    /**
     * Estilos clue
     */

    .clues{
        background-color: var(--clue-bg-color);
        color: var(--clue-color);
    }
    
    .clue-button{
        font-size: 25px;
        border: 3px solid var(--clue-border-color) !important;
    }
    
    .clue-button>h5{

    }
    
    .clue-button>h5>strong{

    }

    .user-edit-avatar img{
        width: 150px;
        height: 150px;
    }

    .upl-img{
        position: relative;
    }

    .upload-new-img{
        position: absolute;
        right: 0px;
        bottom: 0px;
    }

    .dlt-img{
        position: absolute;
        left: 0px;
        bottom: 0px;
    }

    .apply-img{
        position: absolute;
        top: 0px;
        left: 0px;
    }

    .feedback{
        /*background-color: rgb(18, 149, 226);*/
        background-color:#002854;
        color:white;
        border-radius: 5px !important;
    }
    .incorrectmsg{
        background-color: rgb(226, 18, 18);
        color:white;
        font-size: 19px;
        animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
        font-family: 'Oswald Medium';
    }
    @keyframes shake {
        10%, 90% {
            transform: translate3d(-1px, 0, 0);
        }
        
        20%, 80% {
            transform: translate3d(2px, 0, 0);
        }

        30%, 50%, 70% {
            transform: translate3d(-4px, 0, 0);
        }

        40%, 60% {
            transform: translate3d(4px, 0, 0);
        }
    }

    /**
     * Titulo de la escena
     */
    .title-scene{
        font-family: 'Rockwell Nova';
        color: var(--bg-main-color) !important;
        font-size: 29px;
    }

    .description-scene{
        font-size:19px;
        font-family: 'Oswald Medium';
    }

    .correctanswertitle{

    }

    #click_effect{
        position:absolute;
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solide red;
        z-index: 9999;
        pointer-events: none;
    }

    .ripple, .ripple-dark {
        background-position: center;
        transition: background 0.8s;
        border-radius: 100%;
    }

    .effect_visible {
        border-radius: 100%;
        visibility: visible;
        opacity: 1;
        width: 10px;
        height: 10px;
    }

    .effect_hidden {
        visibility: hidden;
        opacity: 0;
        width: 70px;
        height: 70px;
        transition: visibility 0s 0.3s, opacity 0.3s linear, width 0.3s linear, height 0.3s linear;
    }

    /* Button style */
    #effect {
        border: none;
        border-radius: 100%;
        font-size: 16px;
        text-transform: uppercase;
        cursor: pointer;
        color: white;
        background-color: #2196f3;
        box-shadow: 0 0 4px #999;
        outline: none;
    }

    .noselect {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
    }

    .position{
        font-size:30px;
        font-weight: bold;
    }

    /**
     * Activity 1
     */

    .activity1_1 img {
        max-height: 100%;
        max-width: 100%;
    }

    .activity1_2 img {
        max-height: 100%;
        max-width: 100%;
    }

    .activity1_4 img{
        max-height: 100%;
        max-width: 100%;
    }

    .activity2_2 img{
        max-height: 100%;
        max-width: 100%;
    }

    .activity2_4 img {
        max-height: 100%;
        max-width: 100%;
    }

    .activity4_1 img{
        max-height: 100%;
        max-width: 100%;
    }

    /**
     * Lobby
     */

     
    #lobby{
        width: 100%;
        height: 100%;
    }

    #loading{
        top: 0px;
        left: 0px;
        position: relative;
    }

    #lobby-text{
        position: absolute;
        top: 0px;
    }

    /**
     * Textentry
     */

    .bg-codeact{
        background-color: #002855 !important;
    }

    /* Loading animation */
    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ripple div {
        position: absolute;
        border: 4px solid var(--main-color);
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }
    @keyframes lds-ripple {
        0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
        }
    }

    /**
     * End
     */

    #end-text{
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    #end-animation{
        width: 100%;
    }

    .strr0,.strr1,.strr2,.strr3{
        fill:none;
        stroke:#fa4659;
        stroke-width:10;
        stroke-linecap:round;
        stroke-linejoin:round;
        stroke-miterlimit:10;
    }
    
    .strr1,.strr2,.strr3{
        stroke:#0081c6;
    }
    
    .strr2,.strr3{
        stroke:#46cdcf;
    }
    
    .strr3{
        stroke:#fffa96;
    }

    .position-end{
    }

    .logo-long{
        width: auto;
        height: 100%;
    }

    .bg-bad{
        position: relative;
    }

    .bg-bad-img{
        position: absolute;
        width: 60%;
    }

    #end-message{
        position: absolute;
        top: 40px;
        width: 100%;
    }

    .bg-win img{ 
        position: absolute;
        width: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /*background-image: url("@/assets/BG_EndWin.png");
        background-size: 60%;
        background-repeat: no-repeat;
        background-position: center;*/
    }

    .bg-scoreboard>div>img{
        position: absolute;
        width: 70%;
        /*background-image: url("@/assets/BG_Clasificacion.png");
        background-size: 90%;
        background-repeat: no-repeat;
        background-position: center;*/
    }

    .bg-countdown{
        position: relative;
    }

    .bg-inicio{
        position: absolute;
        height: 100%;
        top: 50%;
        left:50%;
        transform: translate(-50%, -50%);
    }

    .end-result{
        font-size: 60px;
    }

    .confeti #amarillo {
        -webkit-animation: move 4s linear infinite;
        animation: move 4s linear infinite;
    }
    .confeti #azul {
        -webkit-animation: move -1s 4s linear infinite;
        animation: move -1s 4s linear infinite;
    }
    .confeti #verde {
        -webkit-animation: move -2s 4s linear infinite;
        animation: move -2s 4s linear infinite;
    }
    .confeti #rojo {
        -webkit-animation: move -3s 4s linear infinite;
        animation: move -3s 4s linear infinite;
    }
    .confeti #amarillo2 {
        -webkit-animation: move -4s 2s linear infinite;
        animation: move -4s 2s linear infinite;
    }
    .confeti #azul2 {
        -webkit-animation: move -2.5s 3s linear infinite;
        animation: move -2.5s 3s linear infinite;
    }
    .confeti #verde2 {
        -webkit-animation: move -1.5s 3.5s linear infinite;
        animation: move -1.5s 3.5s linear infinite;
    }
    .confeti #rojo2 {
        -webkit-animation: move 2s linear infinite;
        animation: move 2s linear infinite;
    }
    .confeti #verde3 {
        -webkit-animation: move -5s 4s linear infinite;
        animation: move -5s 4s linear infinite;
    }
    .confeti #rojo3 {
        -webkit-animation: move -3s 4s linear infinite;
        animation: move -3s 4s linear infinite;
    }
    .confi {
        transform: translate(-200px, -200px);
    }
    .confi2 {
        transform: translate(-500px, -100px);
    }
    .confi3 {
        transform: translate(150px, -300px);
    }
    @keyframes move {
        to {
            transform: translateY(1000px);
        }
    }


   .bg-img-0{
        background-color: var(--main-color);
    }   
    .bg-img-1{
        background-color:#fa4659;
    }   
    .bg-img-2{
        background-color:blueviolet;
    }   
    .bg-img-3{
        background-color: #edf100;
    }   
    .bg-img-4{
        background-color: #3200bb;
    }   
    .bg-img-5{
        background-color: #12cf2b;
    }   
    .bg-img-6{
        background-color: #8d0392;
    }   
    .bg-img-7{
        background-color: #fc9d0e;
    }   
    .bg-img-8{
        background-color: #666b6a;
    }   
    .bg-img-9{
        background-color: #f8c5eb;
    }  

    .feedback-data{
        /*font-size: 1.1em;*/
        font-size: 17px;
    }

    .feedback-message{
        font-style: italic;
        color: var(--main-color);
        font-size: 19px;
    }

    .feedback-text{
        padding-left: 30px;
    }

    .info-correct{
        font-size: 15px;
    }

    .check-btn{
        background-color: var(--bg-main-color) !important;
        outline: none;
        font-size: 1.2em !important;
    }

    .btn-nav{
        color: var(--bg-main-color) !important;
        background: white !important;
        font-size: 20px !important;
        border: none;
        outline: none;
    }

    .btn-nav-red{
        color: white !important;
        background-color: var(--bg-main-color) !important;
        font-size: 20px !important;
        border: none;
        outline: none;
    }

    .list-group-item{
        border: 1px solid var(--clue-border-color) !important;
        font-weight: bold;
    }

    .ghost{
        background: var(--clue-border-color) !important;
    }

    .sentence-escaperoom{
        font-size: 20px;
    }

    .input-escaperoom{
        border: 2px solid var(--clue-border-color) !important;
    }

    .input-escaperoom::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--clue-border-color);
        opacity: 1; /* Firefox */
    }

    .input-escaperoom:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: var(--clue-border-color);
    }

    .input-escaperoom::-ms-input-placeholder { /* Microsoft Edge */
        color: var(--clue-border-color);
    }

    .title-scene-contiainer{
        background-color: rgba(255, 255, 255, 0.82);
        border: 2px solid var(--bg-main-color);
    }

    #intro-text{
        font-family: 'Oswald Medium';
        font-size: 1.2em;
        word-spacing: 0.04em;
        letter-spacing: 0.03em;
        color: white;
    }

    .intro_text{
        font-family: 'Oswald Medium';
        font-size: 1.4em;
        word-spacing: 0.04em;
        letter-spacing: 0.03em;
    }

    .question-title{
        margin-top: 3rem !important;
        margin-left: 3rem !important;
    }

    
    .feedback1-5-text{
        margin-top: 100px !important;
    } 

    .feeback-back-btn{
        position: absolute;
        bottom: 30px;
    }

    .intro_text_order{
        margin-top: 20px;
    }

    @media (max-width: 1500px) {
        .intro_text_order{
            margin-top: 5px !important;
            font-size: 1em !important;
        }
    }

   /* 
    ##Device = Desktops
    ##Screen = 1281px to higher resolution desktops
    */

    @media (min-width: 1281px) {

        *{
            /*color: red;*/
        }


        .intro_text_order{
            margin-top: 15px;
            font-size: 1.2em;
        }
        .time-text{
            /*font-size: 40px;*/
        }

        .clock-container{
            /*height: 100%;*/
        }
        /**
        * Timer
        */
        .timer{
            width: 60px;
            height: 60px;
        }
        .user-info{
            font-size: 30px;
        }

        .username{
            font-size: 30px !important;
        }
        .btn-action{
            font-size: 20px !important;
        }

        /*.bg-scenes{
            position: relative;
            width: auto !important;
            height: 100% !important;
        */
        .time-text{
            font-size: 45px;
        }   

        .btn-nav, .btn-nav-red{
            font-size: 20px !important;
        }
    }

    /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
    */

    @media (min-width: 1025px) and (max-width: 1280px) {

        *{
            /*color: green;*/
        }

        #intro-case{
            font-family: 'Rockwell Nova';
            color: white;
            font-size: 25px;
        }

        #rowtop-feedback-esc1act3{
            height:35%;
            position: relative;
            font-size: 15px;
            padding: 10px;
        }

        #rowbottom-feedback-esc1act3{
            height: 65%;
            position: relative;
            padding: 20px;
        }

        /**
        * Timer
        */

        .activity-container{
            background-color: var(--activity-background) !important;
            font-size: 15px;
        }

        .activity-left-text ul{
            margin: 20px !important;
            padding: 20px !important;
        }

        .timer{
            width: 60px;
            height: 60px;
        }

        .user-info{
            font-size: 30px;
        }

        .username{
            font-size: 30px !important;
        }

        .clock-container{
            /*flex-direction: column;
            height: 100%;*/
        }

        .btn-action{
            font-size: 20px !important;
        }

        .time-text{
            font-size: 45px;
        }   
        
        /*#mapa-svg{
            width: 100%;
        }*/


        .activitytitle{
            font-size: 16px !important;
        }

        .introText{
            font-size: 14px !important;
        }

         .navbar_component{
            height: 55px !important;
        }
        
        #navigationbar{
            height: 100px;
        }
        .logo-long {
            width: auto;
            height: 100%;
        }
        /**
        * Timer
        */
        .timer{
            display: none;
        }

        .timer-text{
            margin:0px;
        }

        .time-text{
            font-size: 29px !important;
        }

        .time-text{
            width: 100% !important;
            height: 100% !important;
            display: table !important;
        }

        .messages_component{
            height: 32px !important;
        }

        .message-icon{
            max-height: 100% !important;
            height: auto !important;
            width: auto !important;
        }

        .clues, .clue-btn{
            font-size: 13px !important;
            color: var(--clue-color);
        }

        .clue-show{
            font-size: 13px !important;
            color: var(--clue-color);
        }

        h1{
            font-size: 27px !important;
        }

        h3{
            font-size: 20px !important;
        }

        h5{
            font-size: 14px !important;
        }

        #btnstartgame{
            font-size: 15px;
        }

        .feedback-data, .feedback-message{
            font-size: 12px !important;
        }

        .activitytitle{
            font-size: 16px !important;
        }

        .introText{
            font-size: 14px !important;
        }

        .btn-secondary{
            margin-bottom: 5px !important;
            font-size: 13px !important;
        }

        .btn-action{
            font-size: 12px !important;
        }


        .check-btn{
            background-color: var(--bg-main-color) !important;
            outline: none;
            font-size: 1.2em !important;
        }

        .button.btn.btn-primary.btn-action.mb-3.rounded-pill{
            font-size: 12px !important;
        }

        .logo-trama{
            width: 100px;
            margin-right: 10px;
        }

        .clock{
            padding:0px;
        }

        .user-info{
            font-size: 30px;
        }

        .username{
            font-size: 30px !important;
        }

        .clock-container{
            /*flex-direction: column;
            height: 100%;*/
        }

        .time-text{
            font-size: 40px;
            font-family: Eurostile;
        }

        .user-avatar{
            width: 60px;
            height: 60px;
        }

        /*.bg-scenes{
            position: relative;
            width: auto !important;
        }*/

        .incorrectmsg{
            font-size: 12px;
        }

        .form-group{
            
        }

        .vue-countdown-component .time-box {
            position: relative;
            box-sizing: border-box;
            font-weight: bold;
            height: 30px !important;
            min-width: 35px !important;
            font-size: 25px !important;
            text-align: center;
            -webkit-perspective: 60px;
            perspective: 78px;
            border-radius: 3px;
            padding: 0 10px;
            color: var(--clock-text-color) !important;
            line-height: 30px !important;
            font-family: 'Avenir', Helvetica, Arial, sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
        
        .sentence-escaperoom{
            font-size: 12px !important;
        }

        .activity_title{
            font-size: 22px !important;
        }   

        .btn-nav, .btn-nav-red{
            font-size: 15px !important;
        }

        .description-scene{
            font-size: 16px;
        }
        
        .question-title{
            margin-top: 20px;
        }
        

        .text-feedback1-2{
            margin-top: 50px !important;
        }
        
        #start{
            background-position-x: 80px !important;
        }

        #personaje_1{
            right: 80px !important;
        }
    }

    /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
    */

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

        *{
            /*color: blue;*/
        }


        .intro_text_order{
            margin-top: 10px;
        }

        #intro-case{
            font-family: 'Rockwell Nova';
            color: white;
            font-size: 25px;
        }

        .text-feedback1-2{
            margin-top: 50px !important;
        }

        .user-info{
            font-size: 30px;
        }

        .username{
            font-size: 30px !important;
        }

        #rowtop-feedback-esc1act3{
            height:35%;
            position: relative;
            font-size: 12px !important;
            padding: 2px !important;
        }

        .clock-container{
            /*flex-direction: column;
            height: 100%;*/
        }

        .activity-container{
            background-color: var(--activity-background) !important;
            font-size: 15px;
        }

        .activity-left-text ul{
            margin: 20px !important;
            padding: 20px !important;
        }

        .time-text{
            font-size: 45px;
        }   

        /*.bg-scenes{
            position: relative;
            width: 100% !important;
            height: auto !important;
        }*/

        .logo-short{
            height: 100%;
        }   
        .logo-long{
            display:none;
        }   

        .vue-countdown-component .time-box {
            position: relative;
            box-sizing: border-box;
            font-weight: bold;
            height: 30px !important;
            min-width: 35px !important;
            font-size: 25px !important;
            text-align: center;
            -webkit-perspective: 60px;
            perspective: 78px;
            border-radius: 3px;
            padding: 0 10px;
            color: var(--clock-text-color) !important;
            line-height: 30px !important;
            font-family: 'Avenir', Helvetica, Arial, sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .activity_title{
            font-size: 22px !important;
        }
        
        .sentence-escaperoom{
            font-size: 10px !important;
        }

        .btn-nav, .btn-nav-red{
            font-size: 15px !important;
        }

        .description-scene{
            font-size: 16px;
        }

        .question-title{
            margin-top: 20px;
        }
        
        #start{
            background-position-x: 60px !important;
        }

        #personaje_1{
            right: 50px !important;
        }
    }

    /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 868px to 1024px
    */

    @media (min-width: 868px) and (max-width: 1024px) {
        
        *{
            /*color: yellow;*/
        }

        #intro-case{
            font-family: 'Rockwell Nova';
            color: rgb(255, 255, 255);
            font-size: 25px;
        }

        .activity-left-text ul {
            margin: 50px !important;
        }

        .navbar_component{
            height: 55px !important;
        }
        
        #navigationbar{
            height: 100px;
        }
        .logo-short{
            display:table;
        }   
        .logo-long{
            display:none;
        }   

        .activity-left-text ul{
            margin: 20px !important;
            padding: 20px !important;
        }
        /**
        * Timer
        */
        .timer{
            display: none;
        }

        .activity_title{
            font-size: 22px !important;
        }

        

        .question-title{
            margin-top: 20px;
        }
        
        .sentence-escaperoom{
            font-size: 10px !important;
        }

        .timer-text{
            margin:0px;
        }

        .time-text{
            font-size: 29px !important;
        }

        .time-text{
            width: 100% !important;
            height: 100% !important;
            display: table !important;
        }

        .messages_component{
            height: 32px !important;
        }

        .message-icon{
            max-height: 100% !important;
            height: auto !important;
            width: auto !important;
        }

        .clues, .clue-btn{
            font-size: 13px !important;
        }

        .clue-show{
            font-size: 13px !important;
        }

        h1{
            font-size: 27px !important;
        }

        h3{
            font-size: 20px !important;
        }

        h5{
            font-size: 14px !important;
        }

        #btnstartgame{
            font-size: 15px;
        }

        .feedback-data, .feedback-message{
            font-size: 12px !important;
        }

        .activitytitle{
            font-size: 16px !important;
        }

        .introText{
            font-size: 14px !important;
        }

        .btn-secondary{
            margin-bottom: 5px !important;
            font-size: 13px !important;
        }

        .btn-action{
            font-size: 12px !important;
        }

        .check-btn{
            background-color: var(--bg-main-color) !important;
            outline: none;
            font-size: 1.2em !important;
        }

        .activity-container{
            background-color: var(--activity-background) !important;
            font-size: 15px;
        }

        .button.btn.btn-primary.btn-action.mb-3.rounded-pill{
            font-size: 12px !important;
        }

        .logo-trama{
            width: 100px;
            margin-right: 10px;
        }

        .clock{
            padding:0px;
        }

        .user-info{
            font-size: 30px;
        }

        .username{
            font-size: 30px !important;
        }

        .clock-container{
            /*flex-direction: column;
            height: 100%;*/
        }

        .time-text{
            font-size: 40px;
            font-family: Eurostile;
        }

        .user-avatar{
            width: 60px;
            height: 60px;
        }

        /*.bg-scenes{
            position: relative;
            height: auto !important;
            width: 100% !important;
        }*/

        .incorrectmsg{
            font-size: 12px;
        }

        .form-group{
            
        }

        .vue-countdown-component .time-box {
            position: relative;
            box-sizing: border-box;
            font-weight: bold;
            height: 30px !important;
            min-width: 35px !important;
            font-size: 25px !important;
            text-align: center;
            -webkit-perspective: 60px;
            perspective: 78px;
            border-radius: 3px;
            padding: 0 10px;
            color: var(--clock-text-color) !important;
            line-height: 30px !important;
            font-family: 'Avenir', Helvetica, Arial, sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .btn-nav, .btn-nav-red{
            font-size: 15px !important;
        }

        .description-scene{
            font-size: 16px;
        }

        
        .feedback1-5-text{
            margin-top: 50px !important;
        } 

        
        #start{
            background-position-x: 50px !important;
        }

        #personaje_1{
            right: 30px !important;
        }
    }

    /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
    */

    @media (min-width: 481px) and (max-width: 867px) {
        *{
            /*color: green !important;*/
        }

        #navigationbar{
             font-size: 1.1em !important;
        }

        #navigationbar{
            height: 40px !important;
        }
        
        #intro-case{
            font-size: 1.0em !important;
        }
        
        #intro-text{
            font-size: 1em !important;
        }

        #intro-button{
            font-size: 0.8em !important;
            padding-top: 5px !important;
            padding-bottom: 5px !important;
            padding-right: 10px !important;
            padding-left: 10px !important;
            margin-top: 5px !important;
        }

        .user-info button, .btn-nav, .btn-nav-generic{
            font-size: 9px !important;
        }

        .logo-trama{
            display:none;
        }

        .question-title{
            margin-top: 20px;
        }

        .ripple, .ripple-dark{
            width: 5px !important;
            height: 5px !important;
        }

        .activity_title{
            margin-top: 10px !important;
        }

        .feedback2-8-text{
            height: 50%;
            margin-top: 0px !important;
        } 

        .feedback2-8-img{
            height: 50%;
        }

        .logo-short, .logo-long, #logo-long-blanco, #logo-long-color{
            display:none !important;
        }


        .activity_title{
            font-size: 22px !important;
        }
        
        .sentence-escaperoom{
            font-size: 10px !important;
        }

        .description-scene{
            font-size: 16px;
        }

        .title-scene,.description-scene{
            margin-left: 0px !important;
            margin-top: 0px !important;
        }

        .title-scene{
            font-size: 13px !important;
        }

        .description-scene{
            font-size: 11px !important;
        }
    }

    /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
    */

    @media (min-width: 481px) and (max-width: 667px) {
        *{
            /*color: green !important;*/
        }

        #navigationbar{
             font-size: 1.1em !important;
        }

        #navigationbar{
            height: 40px !important;
        }
        
        #intro-case{
            font-size: 1.0em !important;
        }
        
        #intro-text{
            font-size: 0.8em !important;
        }

        #intro-button{
            font-size: 0.8em !important;
            padding-top: 5px !important;
            padding-bottom: 5px !important;
            padding-right: 10px !important;
            padding-left: 10px !important;
            margin-top: 5px !important;
        }

        .user-info button, .btn-nav, .btn-nav-generic, .feeback-back-btn{
            font-size: 9px !important;
        }

        .logo-trama{
            display:none;
        }

        .question-title{
            margin-top: 20px;
        }

        

        .feedback2-8-text{
            height: 50%;
            margin-top: 0px !important;
        } 

        .feedback2-8-img{
            height: 50%;
        }

        .logo-short, .logo-long, #logo-long-blanco, #logo-long-color{
            display:none !important;
        }


        .activity_title{
            font-size: 22px !important;
        }
        
        .sentence-escaperoom{
            font-size: 10px !important;
        }

        .description-scene{
            font-size: 16px;
        }

        .title-scene,.description-scene{
            margin-left: 0px !important;
            margin-top: 0px !important;
        }

        .title-scene{
            font-size: 13px !important;
        }

        .description-scene{
            font-size: 11px !important;
        }

        .question-title{
            margin-top: 0px !important;
            margin-left: 0px !important;
        }

        #qandaimage{
            position: absolute !important;
            width: 250px !important;
        }

        #right-size-order{
            height: 400px !important;
        }
    }

    .feedback-area .backbutton {
        margin-top: 10px;
        text-align: center;
    }

    /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
    */

    @media (min-width: 320px) and (max-width: 480px) {

        *{
           /* color: pink;*/
        }
        .user-avatar, .user_avatar_text {
            width: 60px;
            height: 60px;
            border: 5px solid rgba(255, 255, 255, 0.555);
        }


        .activity_title{
            font-size: 22px !important;
        }
        
        .sentence-escaperoom{
            font-size: 10px !important;
        }

        .logo-short, .logo-long{
            display:none;
        }
        
        .timer{
            width: 100%;
            height: 30px;
        }

        .time-text{
            font-size: 30px;
        }

        #bg-start-game{
            background-color: rgba(255, 255, 255, 0.82);
        }

        .logo-trama{
            width: 100px;
            margin-right: 10px;
        }

        .btn-action{
            font-size: 12px !important;
        }

        #navigationbar{
            height: 60px;
        }

        .logo-short{
            display: none;
        }

        .clock-container{
            /*width: 100%;
            height: 30px;*/
        }

        .user-info{
            font-size: 30px;
        }
        
        .timer{
            display:none;
        }

        .time-text{
            font-size: 30px;
        }

        .user-avatar{
            width: 60px;
            height: 60px;
        }
        .username{
            font-size: 30px !important;
        }
        
        /*.bg-scenes, #mapa-svg{
            width:100%;
            height: auto !important;
        }*/

        .title-scene-col{
            height: 30% !important;
        }
        

        .navbar_component{
            height: 55px !important;
        }
        
        #navigationbar{
            height: 100px;
        }
        
        .logo-long {
            width: auto;
            height: 100%;
        }
        /**
        * Timer
        */
        .timer{
            display: none;
        }

        .timer-text{
            margin:0px;
        }

        .time-text{
            font-size: 29px !important;
        }

        .time-text{
            width: 100% !important;
            height: 100% !important;
            display: table !important;
        }

        .messages_component{
            height: 32px !important;
        }

        .message-icon{
            max-height: 100% !important;
            height: auto !important;
            width: auto !important;
        }

        .clues, .clue-btn{
            font-size: 13px !important;
        }

        .clue-show{
            font-size: 13px !important;
        }

        h1{
            font-size: 27px !important;
        }

        h3{
            font-size: 20px !important;
        }

        h5{
            font-size: 14px !important;
        }

        #btnstartgame{
            font-size: 15px;
        }

        .feedback-data, .feedback-message{
            font-size: 12px !important;
        }

        .activitytitle{
            font-size: 16px !important;
        }

        .introText{
            font-size: 14px !important;
        }

        .btn-secondary{
            margin-bottom: 5px !important;
            font-size: 13px !important;
        }

        .btn-action{
            font-size: 12px !important;
        }

        .button.btn.btn-primary.btn-action.mb-3.rounded-pill{
            font-size: 12px !important;
        }

        .logo-trama{
            width: 100px;
            margin-right: 10px;
        }

        .clock{
            padding:0px;
        }

        .user-info{
            font-size: 30px;
        }

        .username{
            font-size: 30px !important;
        }

        .clock-container{
            /*flex-direction: column;
            height: 100%;*/
        }

        .time-text{
            font-size: 40px;
            font-family: Eurostile;
        }

        .user-avatar{
            width: 60px;
            height: 60px;
        }

        .incorrectmsg{
            font-size: 12px;
        }

        .vue-countdown-component .time-box {
            position: relative;
            box-sizing: border-box;
            font-weight: bold;
            height: 30px !important;
            min-width: 35px !important;
            font-size: 25px !important;
            text-align: center;
            -webkit-perspective: 60px;
            perspective: 78px;
            border-radius: 3px;
            padding: 0 10px;
            color: var(--clock-text-color) !important;
            line-height: 30px !important;
            font-family: 'Avenir', Helvetica, Arial, sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .btn-nav, .btn-nav-red{
            font-size: 13px !important;
        }

        .description-scene{
            font-size: 16px;
        }
    }


    /**
    * Loading for list
    */
    .lds-dual-ring {
        display: inline-block;
        width: 80px;
        height: 80px;
    }
    .lds-dual-ring:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid rgb(0, 0, 0);
        border-color: rgb(0, 0, 0) transparent rgb(0, 0, 0) transparent;
        animation: lds-dual-ring 0.7s linear infinite;
    }
    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /**
    * Clue msg
    */
    .clue-msg{
        font-style: italic;
        color: grey;
        font-size: 1.1em;
    }



    /*
        destacado
    */
    .destacado{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100px;
        height: 100px;
    }

    .manito{
        user-select: none;
        position: relative;
        z-index: 9999;
        width: 100px;
        height: 100px;
        background: rgba(239,1,124,1);
        background: radial-gradient(ellipse at center, rgb(255, 0, 204) 43%, rgb(228, 153, 214) 59%, rgba(236, 61, 67, 0) 27%);     
        animation:  lasmanitos 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    @keyframes lasmanitos {
        0% {
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            width: 60px;
            height: 60px;
            opacity: 0;
        }
    }

    .photo_loading{
        position: absolute;
        background-color: white;
        top: 0px;
        left: 0px;
        width: 150px;
        height: 150px;
    }

    .btn-action{
        color: var(--text-color) !important;
    }.clues{
        color: var(--clue-color) !important;
    }

    .form-group{
        margin-bottom: 0px !important;
    }

    /*
    ###############################################################################################################
        Cleant styles
    ###############################################################################################################
     */

    /**
     * Activity title
     */
    .activity_title {
        font-size: 30px;
        color: var(--bg-main-color);
    }


    /* Ripple effect for each activity */
    .ripple, .ripple-dark {
        box-sizing:border-box;
        position:relative;
        width: var(--ripple-size) !important;
        height: var(--ripple-size) !important;
        position: absolute;
    }

    .ripple-dark {
        width: var(--ripple-dark-size) !important;
        height: var(--ripple-dark-size) !important;
    }

    .ripple:before, .ripple-dark:before {
        animation:ripple 2s ease-out infinite;
        border:solid var(--ripple-border) var(--ripple-color);
        border-radius:100%;
        bottom:0;
        box-sizing:border-box;
        content:"";
        left:0;
        position:absolute;
        right:0;
        top:0;
    }

    .ripple-dark:before {
        border:solid var(--ripple-dark-border) var(--ripple-dark-color);
    }

    .ripple:after, .ripple-dark:after {
        animation:ripple 2s 1s ease-out infinite;
        border:solid var(--ripple-border) var(--ripple-color);
        border-radius:100%;
        bottom:0;
        box-sizing:border-box;
        content:"";
        left:0;
        position:absolute;
        right:0;
        top:0;
    }

    .ripple-dark:after {
        border:solid var(--ripple-dark-border) var(--ripple-dark-color);
    }

    @keyframes ripple {
        0% {
            opacity:.65;
        }
        100% {
            border-radius:100%;
            opacity:0;
            transform:scale(3);
        }
    }
</style>
