<template>
    <div :class="['row p-2', (([
                'scene1',
                'scene2',
                'scene3',
                'scene4',
                'scene5',
                'countdown',
                'activity1_1',
                'activity1_2',
                'activity1_3',
                'activity1_4',
                'activity1_5',
                'activity2_1',
                'activity2_2',
                'activity2_3',
                'activity2_4',
                'activity3_1',
                'activity3_2',
                'activity3_3',
                'activity4_1',
                'activity4_2',
                'activity4_3',
                'activity4_4',
                'activity5_1',
                'activity5_2',
                'activity5_3',
                'activity5_3',
                'textentry-activity',
                'order-activity',
                'checks-activity',
                'qanda-activity',
                'radio-activity',
                'end',
                'locked',].indexOf($route.name) > -1) ? 'escaperoom-main-bg animate__animated animate__fadeIn':'')]" id="navigationbar">
        <b-modal id="modal-center" centered hide-footer title-html="<span class='text-white text-rockwell'>¡Cuidado!</span>">
            <p class="my-4 text-oswald">¿Esta seguro que desea reiniciar el juego?</p>
            <hr>
            <div class="col p-0">
                <b-button class="float-right btn escaperoom-main-bg ml-2" @click="resetDemo('modal-center')">Continuar</b-button>
                <b-button class="float-right btn btn-escaperoom" @click="hideModal('modal-center')">Cancelar</b-button>
            </div>
        </b-modal>

        <b-modal size="xl" id="modal-help" ref="modal-help" centered hide-footer title-html="<span class='text-white text-rockwell'>Ayuda</span>">
            <help></help>
        </b-modal>

        <div class="user-info col-5 h-100">
            <div class="h-100 d-flex justify-content-start align-items-center w-100">
                <!--img src="@/assets/logo-redgdps-short.png" class="logo-short"-->
                <!--img src="@/assets/logo-redgdps-white.png" class="logo-long"-->
                <!--logoshort class="logo-short"></logoshort-->
                <logolongcolor id="logo-long-color" :class="['logo-long ml-4', (([
                'scene1',
                'scene2',
                'scene3',
                'scene4',
                'scene5',
                'countdown',
                'activity1_1',
                'activity1_2',
                'activity1_3',
                'activity1_4',
                'activity1_5',
                'activity2_1',
                'activity2_2',
                'activity2_3',
                'activity2_4',
                'activity3_1',
                'activity3_2',
                'activity3_3',
                'activity4_1',
                'activity4_2',
                'activity4_3',
                'activity4_4',
                'activity5_1',
                'activity5_2',
                'activity5_3',
                'activity5_3',
                'textentry-activity',
                'order-activity',
                'checks-activity',
                'qanda-activity',
                'radio-activity',
                'end',
                'locked',].indexOf($route.name) > -1) ? 'd-none':'d-block')]"></logolongcolor>
                <logolongblanco id="logo-long-blanco" :class="['logo-long ml-4', (([
                'scene1',
                'scene2',
                'scene3',
                'scene4',
                'scene5',
                'countdown',
                'activity1_1',
                'activity1_2',
                'activity1_3',
                'activity1_4',
                'activity1_5',
                'activity2_1',
                'activity2_2',
                'activity2_3',
                'activity2_4',
                'activity3_1',
                'activity3_2',
                'activity3_3',
                'activity4_1',
                'activity4_2',
                'activity4_3',
                'activity4_4',
                'activity5_1',
                'activity5_2',
                'activity5_3',
                'activity5_3',
                'textentry-activity',
                'order-activity',
                'checks-activity',
                'qanda-activity',
                'radio-activity',
                'end',
                'locked',].indexOf($route.name) > -1) ? 'd-block':'d-none')]"></logolongblanco>
                <!--img src="@/assets/logo.png" class="logo-trama"-->
                <button v-if="$store.state.game_state != null" :class="['btn rounded mb-0 ml-3 text-oswald', (([
                    'scene1',
                    'scene2',
                    'scene3',
                    'scene4',
                    'scene5',
                    'countdown',
                    'activity1_1',
                    'activity1_2',
                    'activity1_3',
                    'activity1_4',
                    'activity1_5',
                    'activity2_1',
                    'activity2_2',
                    'activity2_3',
                    'activity2_4',
                    'activity3_1',
                    'activity3_2',
                    'activity3_3',
                    'activity4_1',
                    'activity4_2',
                    'activity4_3',
                    'activity4_4',
                    'activity5_1',
                    'activity5_2',
                    'activity5_3',
                    'activity5_3',
                    'textentry-activity',
                    'order-activity',
                    'checks-activity',
                    'qanda-activity',
                    'radio-activity',
                    'end',
                    'locked'].indexOf($route.name) > -1) ? 'btn-nav':'btn-nav-red')]" v-b-modal.modal-center>Volver a comenzar</button>
                    
               
                    <!--div class="my-popper">Habilitar o deshabilitar la pantalla completa</div-->
                    
                    <!--div class="css-1gzdy8a e1qm2hlf2"><button aria-describedby="tooltip-3" class="css-1lgzwz0 e1qm2hlf1">My button</button><div id="tooltip-3" role="tooltip" class="css-pqi3wp" data-popper-placement="bottom" style="display: none; visibility: visible; margin: 0px; position: absolute; inset: 0px auto auto 0px; transform: translate(1081px, 5500px);">My tooltip<div data-popper-arrow="true" class="css-123ym4r e1qm2hlf0" style="position: absolute; left: 0px; transform: translate(5px, 0px);"></div></div></div-->
            </div>            
        </div>
        <div class="clock col-2 h-100 d-flex justify-content-center align-items-center">
            <div v-if="$store.state.timer" class="row no-gutters clock-container">
                <!--timer class="col-md-3 timer"/-->
                <div class="align-middle time-text" >
                    <FlipDown
                        :type="2"
                        :endDate="$store.state.timer"
                        @timeUp="onFinished"
                    />
                </div>
            </div>
        </div>
        <div class="col-5 h-100 d-flex justify-content-end align-items-center">
             <button type="button" :class="['btn rounded mb-0 ml-3 text-oswald', (([
                    'scene1',
                    'scene2',
                    'scene3',
                    'scene4',
                    'scene5',
                    'countdown',
                    'activity1_1',
                    'activity1_2',
                    'activity1_3',
                    'activity1_4',
                    'activity1_5',
                    'activity2_1',
                    'activity2_2',
                    'activity2_3',
                    'activity2_4',
                    'activity3_1',
                    'activity3_2',
                    'activity3_3',
                    'activity4_1',
                    'activity4_2',
                    'activity4_3',
                    'activity4_4',
                    'activity5_1',
                    'activity5_2',
                    'activity5_3',
                    'activity5_3',
                    'textentry-activity',
                    'order-activity',
                    'checks-activity',
                    'qanda-activity',
                    'radio-activity',
                    'end',
                    'locked'].indexOf($route.name) > -1) ? 'btn-nav':'btn-nav-red')]" :tooltip="[($store.state.fs_message) ? '¡Para una mejor experiencia, utilice la pantalla completa!':'']" :flow="[($store.state.fs_message)?'left':'']" id="fsbtn" ref="fsbtn" @click="toggleFullScreen"><font-awesome-icon v-if="is_fullscreen == false" :icon="['fas', 'expand']" /> <font-awesome-icon v-if="is_fullscreen == true" :icon="['fas', 'compress']" /> {{ text_btn_fs }}
                        <!--div v-if="$store.state.fs_message" class="tooltip-fs position-absolute bg-light text-danger p-3" id="tooltip-fullscreen">
                            <div class="position-relative w-100 h-100">
                                <div id="triangle-up"></div>
                                <span>Para una mejor experiencia, utilice la pantalla completa!</span>
                            </div>
                        </div-->
                    </button>
            <button v-if="[
                'scene1',
                'scene2',
                'scene3',
                'scene4',
                'scene5',
                ].indexOf($route.name) > -1" :class="['btn rounded mb-0 ml-3 text-oswald', (([
                    'scene1',
                    'scene2',
                    'scene3',
                    'scene4',
                    'scene5',
                    'countdown',
                    'activity1_1',
                    'activity1_2',
                    'activity1_3',
                    'activity1_4',
                    'activity1_5',
                    'activity2_1',
                    'activity2_2',
                    'activity2_3',
                    'activity2_4',
                    'activity3_1',
                    'activity3_2',
                    'activity3_3',
                    'activity4_1',
                    'activity4_2',
                    'activity4_3',
                    'activity4_4',
                    'activity5_1',
                    'activity5_2',
                    'activity5_3',
                    'activity5_3',
                    'textentry-activity',
                    'order-activity',
                    'checks-activity',
                    'qanda-activity',
                    'radio-activity',
                    'end',
                    'locked'].indexOf($route.name) > -1) ? 'btn-nav':'btn-nav-red'), 'btn-nav-generic']" @click="$router.push({name:'map'})">Volver a la pantalla principal</button>
            <button v-if="[
                'activity1_1',
                'activity1_2',
                'activity1_3',
                'activity1_4',
                'activity1_5',
                'activity2_1',
                'activity2_2',
                'activity2_3',
                'activity2_4',
                'activity3_1',
                'activity3_2',
                'activity3_3',
                'activity4_1',
                'activity4_2',
                'activity4_3',
                'activity4_4',
                'activity5_1',
                'activity5_2',
                'activity5_3',
                'activity5_3',
                'textentry-activity',
                'order-activity',
                'checks-activity',
                'qanda-activity',
                'radio-activity',
                'locked',
                ].indexOf($route.name) > -1" class="btn rounded mb-0 ml-3 btn-nav text-oswald" @click="goBack">Volver</button>
            <button v-if="[
                'scoreboard'
                ].indexOf($route.name) > -1 && $store.state.user.role == 1" class="btn btn-primary btn-action rounded-pill" @click="goBack">Volver</button>
            <button 
            :class="['btn text-oswald ml-3', (([
                    'scene1',
                    'scene2',
                    'scene3',
                    'scene4',
                    'scene5',
                    'countdown',
                    'activity1_1',
                    'activity1_2',
                    'activity1_3',
                    'activity1_4',
                    'activity1_5',
                    'activity2_1',
                    'activity2_2',
                    'activity2_3',
                    'activity2_4',
                    'activity3_1',
                    'activity3_2',
                    'activity3_3',
                    'activity4_1',
                    'activity4_2',
                    'activity4_3',
                    'activity4_4',
                    'activity5_1',
                    'activity5_2',
                    'activity5_3',
                    'activity5_3',
                    'textentry-activity',
                    'order-activity',
                    'checks-activity',
                    'qanda-activity',
                    'radio-activity',
                    'end',
                    'locked'].indexOf($route.name) > -1) ? 'btn-nav':'btn-nav-red'), 'btn-nav-generic']"
                    @click="showHelpModal">
                <font-awesome-icon :icon="['fas', 'question']" />
            </button>
            </div>
        </div>
    </div>
</template>

<script>
    import timer from '@/assets/timer.svg';
    import FlipDown from 'vue-flip-down';
    import logolongcolor from '@/assets/Logo_redGDPS_color.svg';
    import logolongblanco from '@/assets/Logo_redGDPS_blanco.svg';
    import logolong from '@/assets/Logo_redGDPS_color.svg';
    import utils from '../utils'
    import Help from '../views/Help'
    import dotenv from 'dotenv'
    dotenv.config()
    //popper
    import { createPopper } from '@popperjs/core';
    export default {
        name: 'NavBar',
        components: {
            timer, FlipDown, logolongblanco, logolongcolor, Help
        },
        props:{
            leftTime: null
        },
        data: function(){
            return {
                image: '@/assets/logo.png',
                username: "",
                showuserdata: false,
                showmenu: false,
                //Timer (countdown game)
                time: '',
                user_avatar_name: '',
                newAvatar: '',
                is_fullscreen: false,
                text_btn_fs: "Pantalla completa"
            };
        },
        mounted(){
        },
        methods: {
            showHelpModal(){
                this.$refs['modal-help'].show();
            },
            hideHelpModal(){
                this.$refs['modal-help'].hide();
            },
            onFinished: function(){
                vueapp.$store.commit('setGameState', "finished")
                router.push({name:"end"})
            },
            process: function(){
                console.log("process");
            },
            resetDemo: function(id){
                //this.hideModal(id)

                clearInterval(vueapp.$store.state.countdown_interval);
                vueapp.$store.commit("setCountDownInterval", null);
                clearInterval(vueapp.$store.state.timer_interval);
                vueapp.$store.commit("setTimerInterval", null);
                
                console.log("antes-"+vueapp.$store.state.game_state)
                vueapp.$store.commit('resetGlobalState');
                console.log("despues-"+vueapp.$store.state.game_state)
                window.location.href = "/game_"+process.env.VUE_APP_GAME_ID+"/";
            },
            hideModal (id) {
                this.$root.$emit('bv::hide::modal',id)
            },
            goBack(){
                if(typeof vueapp.$store.state.back_url === "string")
                    router.push({name: vueapp.$store.state.back_url}).catch((e)=>{console.log(e)});
                else
                    router.push({name:"map"}).catch((e)=>{console.log(e)});
            },
            toggleFullScreen(){
                var status = utils.toggleFullScreen();
                this.is_fullscreen = (status == true) ? false : true;
                if(status)
                    this.text_btn_fs = "Pantalla completa";
                else       
                    this.text_btn_fs = "Salir de pantalla completa";      

                $('#fsbtn').addClass('no-after');
                vueapp.$store.commit("setFullScreenMessage", false);
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	/*SVG LOGO STYLES*/
	.colorst0{fill-rule:evenodd;clip-rule:evenodd;fill:#1D1D1C;}
	.colorst1{fill:none;stroke:#DB0032;stroke-miterlimit:10;}
	.colorst2{fill:none;stroke:#DB0032;stroke-miterlimit:3.8637;}
	.colorst3{fill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:#DB0032;stroke-miterlimit:3.8637;}
	.colorst4{fill:#DB0032;}
	.colorst5{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
	.colorst6{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;}
	.colorst7{fill:none;stroke:#FFFFFF;stroke-miterlimit:3.8637;}
	.colorst8{fill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:#FFFFFF;stroke-miterlimit:3.8637;}
	.colorst9{fill:#FFFFFF;}

    .no-after:after{content:none !important;}
    .no-after:before{content:none !important;}

    /* START TOOLTIP STYLES */
    [tooltip] {
        position: relative; /* opinion 1 */
        
        pointer-events:auto;
    }

    /* Applies to all tooltips */
    [tooltip]::before,
    [tooltip]::after {
        text-transform: none; /* opinion 2 */
        font-size: .9em; /* opinion 3 */
        line-height: 1;
        user-select: none;
        position: absolute;
        display: none;
        opacity: 0;
        pointer-events:auto;
    }
    [tooltip]::before {
        content: '';
        border: 5px solid transparent; /* opinion 4 */
        z-index: 1001; /* absurdity 1 */
    }
    [tooltip]::after {
        content: attr(tooltip); /* magic! */
    
        /* most of the rest of this is opinion */
        font-family: Helvetica, sans-serif;
        text-align: center;
        
        /* 
            Let the content set the size of the tooltips 
            but this will also keep them from being obnoxious
            */
        min-width: 3em;
        white-space: nowrap;
        overflow: hidden;
        padding: 1ch 1.5ch;
        border-radius: .3ch;
        box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.35);
        background: #333;
        color: #fff;
        z-index: 1000; /* absurdity 2 */
    }

/* Make the tooltips respond to hover */
[tooltip]::before,
[tooltip]::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip='']::before,
[tooltip='']::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -.5em);
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}
[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}
[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, .5em);
}

/* FLOW: LEFT */
[tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  transform: translate(-.5em, -50%);
}
[tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-.5em, -50%);
}

/* FLOW: RIGHT */
[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  transform: translate(.5em, -50%);
}
[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(.5em, -50%);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: .9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: .9;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */ 
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after,
[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]::before,
[tooltip][flow^="left"]::after,
[tooltip][flow^="right"]::before,
[tooltip][flow^="right"]::after {
  animation: tooltips-horz 300ms ease-out forwards;
}


</style>
