<template>
    <div class="activity2_2order w-100 h-100">
        <Order 
            act_id="act_07" 
            :scene_id="2"
            title="Información al paciente"
            question="Determinar el objetivo de control de los FRCV" 
            intro_text="Se explica los posibles riesgos en función a los perfiles aportados" 
            :options="[
                { id: 1, name: '40 mg/dl en hombres', cssclass: 'opcion1', val: null }, 
                { id: 2, name: '130/80 mmHg', cssclass: 'opcion2', val: null },
                { id: 3, name: '7-8%', cssclass: 'opcion3', val: 3 }, 
                { id: 4, name: '<100 mg/dl', cssclass: 'opcion4', val: 4 }, 
                { id: 5, name: '>50 mg/dl en mujeres', cssclass: 'opcion5', val: 5 },
                { id: 6, name: '80-130 mg/dl', cssclass: 'opcion5', val: 6 },
                { id: 7, name: '55 mg/dl en hombres', cssclass: 'opcion5', val: null },
                { id: 8, name: '< 6,5%', cssclass: 'opcion5', val: null },
                { id: 9, name: '140/90 mmHg', cssclass: 'opcion5', val: 9 },
                { id: 10, name: 'Reducción a no más de 5 al dia', cssclass: 'opcion5', val: null },
                { id: 11, name: 'Abstinencia', cssclass: 'opcion5', val: 11 },
            ]" 
            :correct_order="[3, 5, 9, 4, 11, 6 , null, null, null, null, null]"
            :feedback="feedback_content"
            incorrect_msg="La respuesta es incorrecta"
            clue_text="Valores objetivos control promedio (no individualizado)"
            :enunciados="[
                {
                    id: 1,
                    content: 'HbA1c'
                },
                {
                    id: 2,
                    content: 'HDL'
                },
                {
                    id: 3,
                    content: 'TA'
                },
                {
                    id: 4,
                    content: 'LDL'
                },
                {
                    id: 5,
                    content: 'Tabaquismo'
                },
                {
                    id: 6,
                    content: 'Glucemia ayuna'
                },
            ]"
            left_size="col-4"
            right_size="col-8"
            left_column="col-6"
            right_column="col-6"
            row_style="font-size: 13px;height: 52px;"
            >
        </Order>
    </div>
</template>

<script>
import Order from '../activitytype/Order';
import Vue from 'vue';
import FeedbackEsc2Act2 from '../feedbacks/FeedbackEsc2Act2';

export default {
    components: {
        Order
    },
    data:function(){
        return {            
            feedback_content: ""
        };
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "scene2");
        vueapp.$store.commit("setLastURL", "activity2_2");
        setActivityVisited('scene2', 'act_07');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackEsc2Act2)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;
    }
}
</script>