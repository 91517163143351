var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activity3_3checks w-100 h-100"},[_c('Checks',{attrs:{"act_id":"act_11","scene_id":3,"title":"Actuación inicial","intro_text":"Se solicita derivación hospitalaria en ambulancia medicalizada. En el tiempo de espera hasta la llegada de la ambulancia, ¿Qué actitud terapéutica debemos iniciar?","question":"Teniendo en cuenta sus constantes, ¿Qué intervención priorizarías?","options":[
            {
                text: 'Ranitidina',
                value: 0,
            },
            {
                text: 'Nitroglicerina',
                value: 1,
            },
            {
                text: 'Oxigenoterapia',
                value: 2,
            },
            {
                text: 'Omeprazol',
                value: 3,
            },
            {
                text: 'Ácido acetil salicílico',
                value: 4,
            },
            {
                text: 'Clopidogrel',
                value: 5,
            },
            {
                text: 'Metamizol',
                value: 6
            },
            {
                text: 'Diazepam',
                value: 7
            }
        ],"correct_answer":[
            1, 2, 4
        ],"clue_text":"Realizaremos tres intervenciones de manera prioritaria","feedback":_vm.feedback_content,"incorrect_msg":"La respuesta es incorrecta","scenario_finisher":true,"next_scenario":4}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }