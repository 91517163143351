import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'

import Game from '../components/Game.vue'
import Lobby from '../components/lobby/Lobby.vue'
import End from '../components/end/End.vue'

import Loading from '../views/Loading.vue'

//Scenes
import Scene1 from '../components/scenes/scene1/Scene1.vue'
import Scene2 from '../components/scenes/scene2/Scene2.vue'
import Scene3 from '../components/scenes/scene3/Scene3.vue'
import Scene4 from '../components/scenes/scene4/Scene4.vue'
import Scene5 from '../components/scenes/scene5/Scene5.vue'

// Activities testing
import MinChecksActivity from '../components/scenes/activities/MinChecksActivity.vue'
import TextEntryActivity from '../components/scenes/activities/TextEntryActivity.vue'
import OrderActivity from '../components/scenes/activities/OrderActivity.vue'
//import ChecksActivity from '../components/scenes/activities/ChecksActivity.vue'
import QandAActivity from '../components/scenes/activities/QandAActivity.vue'
//import RadioActivity from '../components/scenes/activities/RadioActivity.vue'

// Activities
import Activity1_1 from '../components/scenes/activities/Activity1_1.vue';
import Activity1_2 from '../components/scenes/activities/Activity1_2.vue';
import Activity1_3 from '../components/scenes/activities/Activity1_3.vue';
import Activity1_4Radio from '../components/scenes/activities/Activity1_4Radio.vue';
import Activity1_5Radio from '../components/scenes/activities/Activity1_5Radio.vue';

import Activity2_1 from '../components/scenes/activities/Activity2_1.vue';
import Activity2_2Order from '../components/scenes/activities/Activity2_2Order.vue';
import Activity2_3Order from '../components/scenes/activities/Activity2_3Order.vue';

import Activity3_1 from '../components/scenes/activities/Activity3_1.vue';
import Activity3_2Checks from '../components/scenes/activities/Activity3_2Checks.vue';
import Activity3_3Checks from '../components/scenes/activities/Activity3_3Checks.vue';

import Activity4_1 from '../components/scenes/activities/Activity4_1.vue';
import Activity4_2 from '../components/scenes/activities/Activity4_2.vue';
import Activity4_3Radio from '../components/scenes/activities/Activity4_3Radio.vue';
import Activity4_4Radio from '../components/scenes/activities/Activity4_4Radio.vue';

import Activity5_1 from '../components/scenes/activities/Activity5_1.vue';
import Activity5_2QandA from '../components/scenes/activities/Activity5_2QandA.vue';
import Activity5_3SingleText from '../components/scenes/activities/Activity5_3SingleText.vue';

import OrderActivityScene2 from '../components/scenes/activities/OrderActivityScene2.vue'

//Score Board
import ScoreBoard from '../components/scoreboard/ScoreBoard.vue'

// Login
import Login from '../components/login/Login.vue'

// StartGame
import Start from '../views/Start.vue'
import CountDown from '../views/CountDown.vue'
import Map from '../views/Map.vue'

//Locked activity
import Locked from '../views/Locked.vue'

// Test
import Test from '../views/Test.vue'

import dotenv from 'dotenv'
dotenv.config()

Vue.use(VueRouter)
const game_id = '/game_'+process.env.VUE_APP_GAME_ID;
const routes = [
    {
        path: '/game_'+process.env.VUE_APP_GAME_ID,
        name: 'home',
        component: Game,
        meta: {
            isPublic: true,
        },
        children: [
            {
                path: 'start',
                name: 'start',
                meta: {
                    isPublic: true,
                },
                component: Start
            },
            /*{
                path: 'loading',
                name: 'loading',
                meta: {
                    isPublic: true,
                },
                component: Loading
            },*/
            {
                path: 'countdown',
                name: 'countdown',
                meta: {
                    isPublic: true,
                },
                component: CountDown
            },
            {
                path: 'map',
                name: 'map',
                component: Map,
                meta: {
                    isPublic: true,
                }
            },
            {
                path: 'locked',
                name: 'locked',
                component: Locked,
                meta: {
                    isPublic: true,
                },
                props: {
                    default: true,
                }
            },
            {
                path: 'test',
                name: 'test',
                component: Test,
                meta: {
                    isPublic: true,
                }
            },
            {
                path: 'end',
                name: 'end',
                component: End
            },
            //Scenes
            {
                path: 'scene1',
                name: 'scene1',
                component: Scene1
            },
            {
                path: 'scene2',
                name: 'scene2',
                component: Scene2
            },
            {
                path: 'scene3',
                name: 'scene3',
                component: Scene3
            },
            {
                path: 'scene4',
                name: 'scene4',
                component: Scene4
            },
            {
                path: 'scene5',
                name: 'scene5',
                component: Scene5
            },
            //Games
            {
                path: 'scene1/act_01',
                name: 'activity1_1',
                props: true,
                component: Activity1_1,
            },
            {
                path: 'scene1/act_02',
                name: 'activity1_2',
                props: true,
                component: Activity1_2
            },
            {
                path: 'scene1/act_03',
                name: 'activity1_3',
                props: true,
                component: Activity1_3,
            },
            {
                path: 'scene1/act_04',
                name: 'activity1_4',
                props: true,
                component: Activity1_4Radio,
            },
            {
                path: 'scene1/act_05',
                name: 'activity1_5',
                props: true,
                component: Activity1_5Radio,
            },
            // Scene2
            {
                path: 'scene2/act_06',
                name: 'activity2_1',
                props: true,
                component: Activity2_1,
            },
            {
                path: 'scene2/act_07',
                name: 'activity2_2',
                props: true,
                component: Activity2_2Order,
            },
            {
                path: 'scene2/act_08',
                name: 'activity2_3',
                props: true,
                component: Activity2_3Order,
            },
            //Scene3
            {
                path: 'scene3/act_09',
                name: 'activity3_1',
                props: true,
                component: Activity3_1,
            },
            {
                path: 'scene3/act_10',
                name: 'activity3_2',
                props: true,
                component: Activity3_2Checks,
            },
            {
                path: 'scene3/act_11',
                name: 'activity3_3',
                props: true,
                component: Activity3_3Checks,
            },
            // Scene 4
            {
                path: 'scene4/act_12',
                name: 'activity4_1',
                props: true,
                component: Activity4_1
            },
            {
                path: 'scene4/act_13',
                name: 'activity4_2',
                props: true,
                component: Activity4_2
            },
            {
                path: 'scene4/act_14',
                name: 'activity4_3',
                props: true,
                component: Activity4_3Radio
            },
            {
                path: 'scene4/act_15',
                name: 'activity4_4',
                props: true,
                component: Activity4_4Radio
            },
            //Scene 5
            {
                path: 'scene5/act_16',
                name: 'activity5_1',
                props: true,
                component: Activity5_1
            },
            {
                path: 'scene5/act_17',
                name: 'activity5_2',
                props: true,
                component: Activity5_2QandA
            },
            {
                path: 'scene5/act_18',
                name: 'activity5_3',
                props: true,
                component: Activity5_3SingleText
            }
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    next();
})

export default router