<template>
    <div id="test" class="row h-100 w-100 m-0 p-3">
        <div class="col">
            <table class="border">
                <tbody>
                    <tr>
                        <td>
                            <p><span class="letter">G</span></p>
                        </td>
                        <td>
                            <p><span class="letter">H</span></p>
                        </td>
                        <td>
                            <p><span class="letter">Y</span></p>
                        </td>
                        <td>
                            <p><span class="letter">F</span></p>
                        </td>
                        <td>
                            <p><span class="letter">R</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">D</span></p>
                        </td>
                        <td>
                            <p><span class="letter">S</span></p>
                        </td>
                        <td>
                            <p><span class="letter">W</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">M</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> H</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">P</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td>
                            <p><span class="letter">G</span></p>
                        </td>
                        <td class="afasia">
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">S</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><span class="letter">U&nbsp;</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> N</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td>
                            <p><span class="letter">C</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">B</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">U</span></p>
                        </td>
                        <td>
                            <p><span class="letter">B</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td class="parestesias">
                            <p><span class="letter">S</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">Z</span></p>
                        </td>
                        <td class="afasia">
                            <p><span class="letter">I</span></p>
                        </td>
                        <td>
                            <p><span class="letter">P</span></p>
                        </td>
                        <td>
                            <p><span class="letter">U</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><span class="letter">S</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">D&nbsp;</span></p>
                        </td>
                        <td>
                            <p><span class="letter">P&nbsp;</span></p>
                        </td>
                        <td>
                            <p><span class="letter">Z&nbsp;</span></p>
                        </td>
                        <td>
                            <p><span class="letter">T</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">F</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">N</span></p>
                        </td>
                        <td class="parestesias">
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> P</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I&nbsp;</span></p>
                        </td>
                        <td class="afasia">
                            <p><span class="letter">S</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> A</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> O</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> S</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">S</span></p>
                        </td>
                        <td>
                            <p><span class="letter">T</span></p>
                        </td>
                        <td>
                            <p><span class="letter">R</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">N</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">T</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td>
                            <p><span class="letter">C</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td class="parestesias">
                            <p><span class="letter">I</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">N</span></p>
                        </td>
                        <td class="afasia">
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">C</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">R</span></p>
                        </td>
                        <td>
                            <p><span class="letter">T</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><span class="letter">N</span></p>
                        </td>
                        <td class="confusion">
                            <p><span class="letter">C</span></p>
                        </td>
                        <td class="confusion">
                            <p><span class="letter">O</span></p>
                        </td>
                        <td class="confusion">
                            <p><span class="letter">N</span></p>
                        </td>
                        <td class="confusion">
                            <p><span class="letter">F</span></p>
                        </td>
                        <td class="confusion">
                            <p><span class="letter">U</span></p>
                        </td>
                        <td class="confusion">
                            <p><span class="letter">S</span></p>
                        </td>
                        <td class="confusion">
                            <p><span class="letter">I</span></p>
                        </td>
                        <td class="confusion">
                            <p><span class="letter">O</span></p>
                        </td>
                        <td class="confusion">
                            <p><span class="letter">N</span></p>
                        </td>
                        <td class="parestesias">
                            <p><span class="letter">S</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> C</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td class="afasia">
                            <p><span class="letter">F</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">R</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><span class="letter">T&nbsp;</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O&nbsp;</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">D</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">N</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td>
                            <p><span class="letter">P</span></p>
                        </td>
                        <td class="parestesias">
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> T</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td>
                            <p><span class="letter">D</span></p>
                        </td>
                        <td class="afasia">
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">R</span></p>
                        </td>
                        <td>
                            <p><span class="letter">D</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td class="cefalea">
                            <p><span class="letter">C</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">N</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><span class="letter">E&nbsp;</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">C</span></p>
                        </td>
                        <td>
                            <p><span class="letter">H</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td>
                            <p><span class="letter">Z</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td class="parestesias">
                            <p><span class="letter">T</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">W</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">F</span></p>
                        </td>
                        <td>
                            <p><span class="letter">R</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">N</span></p>
                        </td>
                        <td class="cefalea">
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">X</span></p>
                        </td>
                        <td>
                            <p><span class="letter">P</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><span class="letter">P</span></p>
                        </td>
                        <td>
                            <p><span class="letter">U</span></p>
                        </td>
                        <td>
                            <p><span class="letter">S</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td class="parestesias">
                            <p><span class="letter">S</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">T</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">T</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">G</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">R</span></p>
                        </td>
                        <td class="cefalea">
                            <p><span class="letter">F</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I&nbsp;</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td>
                            <p><span class="letter">V</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">P</span></p>
                        </td>
                        <td class="parestesias">
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">T</span></p>
                        </td>
                        <td>
                            <p><span class="letter">R</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">F</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">F</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td class="cefalea">
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">V</span></p>
                        </td>
                        <td>
                            <p><span class="letter">U</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><span class="letter">L&nbsp;</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A&nbsp;</span></p>
                        </td>
                        <td>
                            <p><span class="letter">B</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">R</span></p>
                        </td>
                        <td class="parestesias">
                            <p><span class="letter">R</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">S</span></p>
                        </td>
                        <td class="visiondoble">
                            <p><span class="letter">V</span></p>
                        </td>
                        <td class="visiondoble">
                            <p><span class="letter">I</span></p>
                        </td>
                        <td class="visiondoble">
                            <p><span class="letter">S</span></p>
                        </td>
                        <td class="visiondoble">
                            <p><span class="letter">I</span></p>
                        </td>
                        <td class="visiondoble">
                            <p><span class="letter">O</span></p>
                        </td>
                        <td class="visiondoble">
                            <p><span class="letter">N</span></p>
                        </td>
                        <td class="visiondoble">
                            <p><span class="letter">D</span></p>
                        </td>
                        <td class="visiondoble">
                            <p><span class="letter">O</span></p>
                        </td>
                        <td class="visiondoble">
                            <p><span class="letter">B</span></p>
                        </td>
                        <td class="cefalea visiondoble">
                            <p><span class="letter">L</span></p>
                        </td>
                        <td class="visiondoble">
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">T</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> R</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">P</span></p>
                        </td>
                        <td class="parestesias">
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">C</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">F</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">M</span></p>
                        </td>
                        <td>
                            <p><span class="letter">B</span></p>
                        </td>
                        <td>
                            <p><span class="letter">R</span></p>
                        </td>
                        <td class="cefalea">
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td class="disartria">
                            <p><span class="letter">A</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><span class="letter">G</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">N</span></p>
                        </td>
                        <td class="parestesias">
                            <p><span class="letter">P</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">S</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">P</span></p>
                        </td>
                        <td>
                            <p><span class="letter">G</span></p>
                        </td>
                        <td>
                            <p><span class="letter">F</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">W</span></p>
                        </td>
                        <td>
                            <p><span class="letter">N</span></p>
                        </td>
                        <td>
                            <p><span class="letter">H</span></p>
                        </td>
                        <td class="cefalea">
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">B</span></p>
                        </td>
                        <td class="disartria">
                            <p><span class="letter">I</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> J</span></p>
                        </td>
                        <td>
                            <p><span class="letter">F</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">S</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">P</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td class="coma">
                            <p><span class="letter">C</span></p>
                        </td>
                        <td class="coma">
                            <p><span class="letter">O</span></p>
                        </td>
                        <td class="coma">
                            <p><span class="letter">M</span></p>
                        </td>
                        <td class="coma">
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">G</span></p>
                        </td>
                        <td>
                            <p><span class="letter">U</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td>
                            <p><span class="letter">R</span></p>
                        </td>
                        <td>
                            <p><span class="letter">R</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">Y</span></p>
                        </td>
                        <td class="disartria">
                            <p><span class="letter">R</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><span class="letter">N</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">S</span></p>
                        </td>
                        <td>
                            <p><span class="letter">T</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">Y</span></p>
                        </td>
                        <td>
                            <p><span class="letter">U</span></p>
                        </td>
                        <td>
                            <p><span class="letter">S</span></p>
                        </td>
                        <td>
                            <p><span class="letter">T</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">G</span></p>
                        </td>
                        <td>
                            <p><span class="letter">U</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td>
                            <p><span class="letter">T</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">R</span></p>
                        </td>
                        <td>
                            <p><span class="letter">R</span></p>
                        </td>
                        <td>
                            <p><span class="letter">S</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td class="disartria">
                            <p><span class="letter">T</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> S</span></p>
                        </td>
                        <td>
                            <p><span class="letter">C</span></p>
                        </td>
                        <td>
                            <p><span class="letter">U</span></p>
                        </td>
                        <td>
                            <p><span class="letter">R</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td>
                            <p><span class="letter">D</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">R</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">P</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">S</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td>
                            <p><span class="letter">T</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td class="disartria">
                            <p><span class="letter">R</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><span class="letter">S</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> I</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td class="convulsion">
                            <p><span class="letter">C</span></p>
                        </td>
                        <td class="convulsion">
                            <p><span class="letter">O</span></p>
                        </td>
                        <td class="convulsion">
                            <p><span class="letter">N</span></p>
                        </td>
                        <td class="convulsion">
                            <p><span class="letter">V</span></p>
                        </td>
                        <td class="convulsion">
                            <p><span class="letter">U</span></p>
                        </td>
                        <td class="convulsion">
                            <p><span class="letter">L</span></p>
                        </td>
                        <td class="convulsion">
                            <p><span class="letter">S</span></p>
                        </td>
                        <td class="convulsion">
                            <p><span class="letter">I</span></p>
                        </td>
                        <td class="convulsion">
                            <p><span class="letter">O</span></p>
                        </td>
                        <td class="convulsion">
                            <p><span class="letter">N</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> T</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">R</span></p>
                        </td>
                        <td>
                            <p><span class="letter">R</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td class="disartria">
                            <p><span class="letter">A</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><span class="letter">T</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">C</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">D</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">P</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">M</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">G</span></p>
                        </td>
                        <td>
                            <p><span class="letter">N</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">T</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td>
                            <p><span class="letter">C</span></p>
                        </td>
                        <td>
                            <p><span class="letter">D</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td class="disartria">
                            <p><span class="letter">S</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">B&nbsp;</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">D</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td>
                            <p><span class="letter">U</span></p>
                        </td>
                        <td>
                            <p><span class="letter">T</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">R</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">Q</span></p>
                        </td>
                        <td>
                            <p><span class="letter">U</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">S</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">S</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                        <td class="disartria">
                            <p><span class="letter">I</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><span class="letter">M&nbsp;</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> O</span></p>
                        </td>
                        <td>
                            <p><span class="letter">D</span></p>
                        </td>
                        <td>
                            <p><span class="letter">A</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">D</span></p>
                        </td>
                        <td>
                            <p><span class="letter">Y</span></p>
                        </td>
                        <td>
                            <p><span class="letter">U</span></p>
                        </td>
                        <td>
                            <p><span class="letter">I</span></p>
                        </td>
                        <td>
                            <p><span class="letter">D</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">S</span></p>
                        </td>
                        <td>
                            <p><span class="letter">T</span></p>
                        </td>
                        <td>
                            <p><span class="letter">R</span></p>
                        </td>
                        <td>
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter">Z</span></p>
                        </td>
                        <td>
                            <p><span class="letter">U</span></p>
                        </td>
                        <td>
                            <p><span class="letter">Y</span></p>
                        </td>
                        <td class="disartria">
                            <p><span class="letter">D</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><span class="letter">Y</span></p>
                        </td>
                        <td class="marchainestable">
                            <p><span class="letter">M</span></p>
                        </td>
                        <td class="marchainestable">
                            <p><span class="letter">A</span></p>
                        </td>
                        <td class="marchainestable">
                            <p><span class="letter">R</span></p>
                        </td>
                        <td class="marchainestable">
                            <p><span class="letter">C</span></p>
                        </td>
                        <td class="marchainestable">
                            <p><span class="letter">H</span></p>
                        </td>
                        <td class="marchainestable">
                            <p><span class="letter">A</span></p>
                        </td>
                        <td class="marchainestable">
                            <p><span class="letter">I</span></p>
                        </td>
                        <td class="marchainestable">
                            <p><span class="letter">N</span></p>
                        </td>
                        <td class="marchainestable">
                            <p><span class="letter">E</span></p>
                        </td>
                        <td class="marchainestable">
                            <p><span class="letter">S</span></p>
                        </td>
                        <td class="marchainestable">
                            <p><span class="letter">T</span></p>
                        </td>
                        <td class="marchainestable">
                            <p><span class="letter">A</span></p>
                        </td>
                        <td class="marchainestable">
                            <p><span class="letter">B</span></p>
                        </td>
                        <td class="marchainestable">
                            <p><span class="letter">L</span></p>
                        </td>
                        <td class="marchainestable">
                            <p><span class="letter">E</span></p>
                        </td>
                        <td>
                            <p><span class="letter"> B</span></p>
                        </td>
                        <td>
                            <p><span class="letter">B</span></p>
                        </td>
                        <td>
                            <p><span class="letter">L</span></p>
                        </td>
                        <td>
                            <p><span class="letter">O</span></p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col">
            <span>Encontradas: <b>{{ soup.length }} de {{ solution.length }}</b></span>
            <ul>
                <li v-for="letter in soup" :key="letter.id">
                    {{ letter.value }}
                </li>
            </ul>
            <input type="text" id="soupletter" ref="soupletter" @keyup="FormatInput" v-model="soupletter" placeholder="Introduce la palabra que has encontrado">
            <button @click="Validate">Validar</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Test',
        data: function(){
            return {
                soupletter: null,
                soup: [],
                solution: [
                    {id: 1, value: "CONFUSION"},
                    {id: 2, value: "AFASIA"},
                    {id: 3, value: "CEFALEA"},
                    {id: 4, value: "VISION DOBLE"},
                    {id: 5, value: "MARCHA INESTABLE"},
                    {id: 6, value: "PARESTESIAS"},
                    {id: 7, value: "CONVULSION"},
                    {id: 8, value: "DISARTRIA"},
                    {id: 9, value: "COMA"},
                ],
            };
        },
        methods: {
            FormatInput: function(){
                this.soupletter = this.soupletter.toUpperCase();
            },
            Validate: function(){
                var found = false;
                for(var i = 0; i < this.solution.length && found == false; i++){
                    var space_cleant = this.solution[i].value.replace(/\s/g, '');
                    if(this.solution[i].value == this.soupletter || space_cleant == this.soupletter){
                        found = true;
                        console.log()
                        if(!this.soup.filter(e => e.value === this.solution[i].value).length > 0){
                            this.soup.push({id:this.solution[i].id,value:this.solution[i].value});
                            this.soupletter = "";
                            $('.'+space_cleant.toLowerCase()).css('background','#68acff');
                            $('.'+space_cleant.toLowerCase()).css('color','white');
                        }else{
                            console.log("Ya existe")
                        }
                    }
                }

                if(this.soup.length == this.solution.length){
                    console.log("todo ok")
                }
            }
        }
    }
</script>

<style scoped>
    .letter{
        
    }

    table { 
        border: 2px outset grey / darkgray; 
        padding: 1px;
    }

    table > tbody > tr > td{
        width: 30px;
        height: 30px;
        border: 1px solid darkgray; 
        margin: 1px;
    }
    
    table > tbody > tr > td > p{
        margin-bottom: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>