<template>
    <div class="row login d-flex justify-content-center align-items-center">
        <div class="col-6">
            <div class="card">
                <div class="card-header">
                    Entrar
                </div>
                <div class="card-body">            
                    <form id="loginform" v-on:submit="doLogin" action="#" method="post">
                        <div class="form-group" :class="(validation.username != '')?'alert alert-danger':''">
                            <label for="exampleInputUsername">Nombre</label>
                            <input autocomplete type="text" class="form-control" id="exampleInputUsername" placeholder="Nombre de usuario" v-model="input.username">
                            <div :v-if="validation.username" class="error_msg">{{ validation.username }}</div>
                        </div>
                        <div class="form-group" :class="(validation.password != '')?'alert alert-danger':''">
                            <label for="exampleInputPassword1">Contraseña</label>
                            <input autocomplete type="password" class="form-control" id="exampleInputPassword1" placeholder="Contraseña" v-model="input.password">
                            <div :v-if="validation.password" class="error_msg">{{ validation.password }}</div>
                        </div>
                        <div v-if="$store.state.login_validation.error != null" >
                            <b-alert show variant="warning">
                                {{ $store.state.login_validation.error }}
                            </b-alert>
                        </div>
                        <div class="error" v-if="$store.state.login_error != ''">
                            {{ $store.state.login_error_msg }}
                        </div>
                        <input type="submit" class="btn btn-primary" value="Entrar" />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                input: {
                    username: '',
                    password: ''
                },
                validation: {
                    username: '',
                    password: '',
                    error: '',
                }
            }
        },
        methods: {
            doLogin: function(evt) {
                evt.preventDefault();
                if (this.input.username == '') this.validation.username = 'Campo requerido';
                else this.validation.username = '';
                if (this.input.password == '') this.validation.password = 'Campo requerido';
                else this.validation.password = '';

                const expression = /^\S+$/g;
                if(/ /.test(this.input.username)) this.validation.username = 'El campo no permite espacios';
                if(/ /.test(this.input.password)) this.validation.password = 'El campo no permite espacios';

                if( this.input.username != '' && 
                    this.input.password != '' && 
                    !/ /.test(this.input.username) && 
                    !/ /.test(this.input.password)){
                    vueapp.$store.commit('resetGlobalState');
                    vueapp.close();
                    fireNetworkAction('login', this.input);
                }
            },
            has_any_spaces(regex, str) {
                if (regex.test(str) || str === '') {
                    return false;
                }
                return true;
            }
        }
    }
</script>

<style scoped>
form {
    text-align: left;
}
</style>
