<template>
    <QandA :questions="[
        {
            id: 0,
            text: 'Pregunta 1',
        },
        {
            id: 1,
            text: 'Pregunta 2',
        },
        {
            id: 2,
            text: 'Pregunta 3',
        },
        {
            id: 3,
            text: 'Pregunta 4',
        },
        {
            id: 4,
            text: 'Pregunta 5',
        }
    ]" :answers="[
        'Respuesta 1',
        'Respuesta 2',
        'Respuesta 3',
        'Respuesta 4',
        'Respuesta 5',
    ]"></QandA>
</template>

<script>
import QandA from '../activitytype/QandA';

export default {
    components: {
        QandA,
    },
    mounted: function() {
        vueapp.$store.commit('setBackURL', '/scene1');
    }
}
</script>