<template>
    <div class="activity3_2checks w-100 h-100">
        <Checks 
            act_id="act_10"
            :scene_id="3"
            title="Recepción del paciente en zona de Urgencia y paso a box"
            intro_text="Se debe realizar una toma de constantes inicial" 
            question="¿Qué constantes deben priorizarse?"             
            :options="[
                {
                    text: 'Glucemia',
                    value: 0,
                },
                {
                    text: 'Temperatura',
                    value: 1,
                },
                {
                    text: 'Combur test',
                    value: 2,
                },
                {
                    text: 'Tensión arterial',
                    value: 3,
                },
                {
                    text: 'Saturación O2',
                    value: 4,
                },
                {
                    text: 'Pulsos pedios',
                    value: 5,
                },
                {
                    text: 'Frecuencia cardiaca',
                    value: 6
                },
                {
                    text: 'Posición 45º',
                    value: 7
                }
            ]" 
            :correct_answer="[
                0, 3, 4, 6
            ]"
            clue_text="Elegir 4 opciones"
            :feedback="feedback_content"
            incorrect_msg="La respuesta es incorrecta"
        ></Checks>
    </div>
</template>

<script>
import Checks from '../activitytype/Checks';
import Vue from 'vue';
import FeedbackEsc3Act2 from '../feedbacks/FeedbackEsc3Act2';

export default {
    components: {
        Checks
    },
    data:function(){
        return {            
            feedback_content: ""
        };
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "scene3");
        vueapp.$store.commit("setLastURL", "activity3_2");
        setActivityVisited('scene3', 'act_10');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackEsc3Act2)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;
    }
}
</script>