<template>
    <div class="activity4_2 w-100 h-100 d-flex justify-content-center align-items-center animate__animated animate__fadeIn">      
        <div class="row activity-container w-100 h-100 overflow-hidden">
            <div class="col h-100 p-0 d-flex justify-content-center align-items-center">
                <!--img id="esc_4_act_2_1" class="float-right" src="@/assets/esc_4_act_2_1-min.png" alt="esc_4_act_2_1" rel="preload"-->
                <img id="esc_4_act_2" src="@/assets/esc_4_act_2.png" alt="esc_4_act_2" rel="preload">
                <div class="w-100 d-flex justify-content-end align-items-center feeback-back-btn mr-5">
                    <button class="btn btn-action mt-3 mb-3 rounded check-btn mr-5" onclick="router.push({name:'scene4'});">Volver</button>
                </div>
            </div> 
        </div>
    </div>
</template>

<script>
export default {
    name: "act_13",
    mounted: function() {
        vueapp.$store.commit("setBackURL", "scene4");
        vueapp.$store.commit("setLastURL", "activity4_2");
        setActivityVisited('scene4', 'act_13');
        var act = getActivityStatus(4, 'act_13');
        if(act.completed == false)
            vueapp.$store.commit("setScore", vueapp.$store.state.score + 1);
        setActivityCompleted('scene4', 'act_13', true);
    }    
}
</script>

<style scoped>
    #esc_4_act_2{
        max-width: 100%;
        max-height: 100%;
    }
</style>