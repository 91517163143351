<template>
    <div class="activity3_1 w-100 h-100 d-flex justify-content-center align-items-center animate__animated animate__fadeIn">
        <div class="row activity-container w-100 h-100 d-flex justify-content-center align-items-center">
            <img id="esc_3_act_1" class="p-5" src="@/assets/EKG_E2_A2.jpg" alt="esc_3_act_1" rel="preload">
            <div class="w-100 d-flex justify-content-end align-items-center feeback-back-btn mr-5">
                <button class="btn btn-action mt-3 mb-3 rounded check-btn mr-5" onclick="router.push({name:'scene3'});">Volver</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "act_09",
    mounted: function() {        
        vueapp.$store.commit("setBackURL", "scene3");
        vueapp.$store.commit("setLastURL", "activity3_1");
        setActivityVisited('scene3', 'act_09');
        var act = getActivityStatus(3, 'act_09');
        if(act.completed == false)
            vueapp.$store.commit("setScore", vueapp.$store.state.score + 1);
        setActivityCompleted('scene3', 'act_09', true);
    }
}
</script>

<style scoped>
    #esc_3_act_1{
        max-width: 100%;
        max-height: 100%;
    }
</style>