<template>
    <div class="end w-100 h-100 animate__animated animate__fadeIn">
        <div v-if="$store.state.game_over" id="gameover-container" class="h-100 w-100 d-flex justify-content-center align-items-center flex-column">
            <img id="pantalla_perder" src="@/assets/pantalla_perder.png" class="h-50">        
            <h1 class="mt-4 text-rockwell">Se ha terminado el tiempo</h1>
            <h3 class="mt-4 text-rockwell">¡No has completado el juego!</h3>
        </div>
        <div v-else class="w-100 h-100 d-flex justify-content-center align-items-center flex-column position-relative">
            <div class="row activity-container w-100 h-100" id="prueba-background" ref="prueba-background">
                <div class="w-100 h-100 position-absolute" id="heart-good-bg"></div>
                <div class="w-100 h-100 d-flex justify-content-center align-items-center flex-column p-4">
                    <!--img class="images-end animate__heartBeat" src="@/assets/pantalla_ganar.png" alt="pantalla_ganar"-->
                    <div class="col-9 text-oswald text-center mt-5">
                        <h1 class="mt-5 text-rockwell">¡Enhorabuena!</h1>
                        <h3 class="mt-5 text-rockwell">¡Has completado el caso!</h3>
                        <p class="text-oswald">Finalización con el diagnóstico y alta hospitalaria completo:</p>
                        <span class="text-oswald">Informe de alta hospitalaria</span>

                        Se realizó Coronariografía: enfermedad arterial coronaria <b>monovaso:  lesión del 100% en CD medial;</b> se realiza <b>ANGIOPLASTIA PRIMARIA</b> sobre dicha lesión e implantación de Stent con resultado excelente,  flujo TIMI II-III. FEVI 57%. 
                    
                        <p class="text-oswald">Diagnóstico al alta: SCASEST: IAM INFERIOR de 2 horas y media de evolución, Killip I.</p>
                    </div>
                </div>
            </div>
            <svg preserveAspectRatio="none" id="end-animation" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080">
                <g class="confeti"><path id="rojo3" class="strr0" d="M700.4 139.6L679 136"/><path id="rojo2" class="strr0" d="M879.9 130.4l-14.2-16.3"/><path id="rojo" class="strr0" d="M509.7 36.1l21.5-2.3"/><path id="azul2" class="strr1" d="M649.4 27.1l19.5-9.4"/><path id="verde3" class="strr2" d="M722.6 74.5L713.2 55"/><path id="amarillo" class="strr3" d="M791.9 37.9l20.4-7.3"/><path id="azul" class="strr1" d="M801.8 109.1l-17.1 13.3"/><path id="verde2" class="strr2" d="M523.2 128.1l16.3 14.2"/><path id="verde" class="strr2" d="M922.4 47.8l-18.8 10.8"/><path id="amarillo2" class="strr3" d="M612.7 80.7l-18.4 11.5"/></g>
                <g class="confeti confi" x="-200" y="20"><path id="rojo3" class="strr0" d="M700.4 139.6L679 136"/><path id="rojo2" class="strr0" d="M879.9 130.4l-14.2-16.3"/><path id="rojo" class="strr0" d="M509.7 36.1l21.5-2.3"/><path id="azul2" class="strr1" d="M649.4 27.1l19.5-9.4"/><path id="verde3" class="strr2" d="M722.6 74.5L713.2 55"/><path id="amarillo" class="strr3" d="M791.9 37.9l20.4-7.3"/><path id="azul" class="strr1" d="M801.8 109.1l-17.1 13.3"/><path id="verde2" class="strr2" d="M523.2 128.1l16.3 14.2"/><path id="verde" class="strr2" d="M922.4 47.8l-18.8 10.8"/><path id="amarillo2" class="strr3" d="M612.7 80.7l-18.4 11.5"/></g>
                <g class="confeti confi2" x="-200" y="20"><path id="rojo3" class="strr0" d="M700.4 139.6L679 136"/><path id="rojo2" class="strr0" d="M879.9 130.4l-14.2-16.3"/><path id="rojo" class="strr0" d="M509.7 36.1l21.5-2.3"/><path id="azul2" class="strr1" d="M649.4 27.1l19.5-9.4"/><path id="verde3" class="strr2" d="M722.6 74.5L713.2 55"/><path id="amarillo" class="strr3" d="M791.9 37.9l20.4-7.3"/><path id="azul" class="strr1" d="M801.8 109.1l-17.1 13.3"/><path id="verde2" class="strr2" d="M523.2 128.1l16.3 14.2"/><path id="verde" class="strr2" d="M922.4 47.8l-18.8 10.8"/><path id="amarillo2" class="strr3" d="M612.7 80.7l-18.4 11.5"/></g>
                <g class="confeti confi3" x="-200" y="20"><path id="rojo3" class="strr0" d="M700.4 139.6L679 136"/><path id="rojo2" class="strr0" d="M879.9 130.4l-14.2-16.3"/><path id="rojo" class="strr0" d="M509.7 36.1l21.5-2.3"/><path id="azul2" class="strr1" d="M649.4 27.1l19.5-9.4"/><path id="verde3" class="strr2" d="M722.6 74.5L713.2 55"/><path id="amarillo" class="strr3" d="M791.9 37.9l20.4-7.3"/><path id="azul" class="strr1" d="M801.8 109.1l-17.1 13.3"/><path id="verde2" class="strr2" d="M523.2 128.1l16.3 14.2"/><path id="verde" class="strr2" d="M922.4 47.8l-18.8 10.8"/><path id="amarillo2" class="strr3" d="M612.7 80.7l-18.4 11.5"/></g>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'End',
        data: function(){
            return {
                score: null,
                cssProps: {
                    backgroundImage: 'url('+require('@/assets/pantalla_ganar.png')+');'
                }
            };
        },
        mounted: function(){        
            vueapp.$store.commit("setLastURL", "end");
            if(vueapp.$store.state.game_over){
                if(vueapp.$store.state.gameover != null)
                    vueapp.$store.state.gameover.play();
            }else{
                if(vueapp.$store.state.end != null)
                    vueapp.$store.state.end.play();
            }
        },
        methods: {  
            
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .game{
        overflow: hidden !important;
    }
    #end-animation{
        position: absolute;
        top: 0px;
    }    

    .images-end{
        max-width: 100%;
        max-height: 100%;
    }

    .rowtop-feedback-esc5act4{
        height:10%;
        position: relative;
        font-size: 15px;
        padding: 10px;
    }

    .rowbottom-feedback-esc5act4{
        height: 90%;
        position: relative;
        padding: 20px;
    }

    #heart-good-bg{
        background-image: url('~@/assets/pantalla_ganar.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 60%;
        opacity: 0.25;
    }
</style>
