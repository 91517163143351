<template>
    <div class="row h-100 w-100 m-0 d-flex justify-content-center align-items-center position-relative">
        <img id="pantalla_inicio" class="animate__animated animate__zoomIn" src="@/assets/pantalla_inicio.png" alt="pantalla_inicio">
        <span id="countdown">{{ $store.state.countdown }}</span>
    </div>
</template>

<script>
    export default {
        name : 'CountDown',
        data: function(){
            return {
                countdown_timer: null,
                beep: null
            };
        },
        destroy: function(){
            clearInterval(this.countdown_timer);
            vueapp.$store.commit("setCountDownInterval", null);
        },
        mounted : function(){
            vueapp.$store.state.beep.pause();
            vueapp.$store.state.beep.play();
            if(vueapp.$store.state.game_state != "started")
                startCountDown();
            else
                router.push({name:"map"});
        },
        methods: {
            play : function() {
                vueapp.$store.state.beep.play();
            }
        }
    }
</script>

<style scoped>
    #pantalla_inicio{
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        margin: 30px;
    }
    #countdown{
        font-size: 200px;
        z-index: 9999;
    }
</style>
