<template>
    <div id="start-background" class="h-100 w-100">
        <div id="start" class="row h-100 w-100 m-0 p-3" :style="cssProps">
            <div class="col-sm-8 col-md-6 col-lg-5 col-xl-4 d-flex justify-content-center align-items-start flex-column">
                <h2 id="intro-case" class="mb-3">CASO {{ caso_clinico }}</h2>
                <div class="d-flex justify-content-center align-items-center flex-column">
                    <div id="intro-text">
                        <p>Manu tiene 63 años y padece diabetes desde hace 12 años.</p>
                        <p>Está en tratamiento con metformina 1000 cada 12 horas glibenclamida 3 cp de 5 mg al día AAS 150 mg al día desde hace 5 años. Además es hipertenso en tratamiento con enalapril 20 mg al día e hidroclorotiazida 12,5. Hiperlipemia en tratamiento con simvastatina 20 mg día.</p>
                    </div>
                    <button type="button" id="intro-button" class="btn btn-light text-dark font-weight-bold p-3 pr-5 pl-5 mt-4" @click="Start()">COMENZAR</button>
                    <!--h5 class="mt-3 bg-dark text-white p-2" id="fs-message">¡Para una mejor experiencia, utilice la pantalla completa!</h5-->
                </div>
            </div>
            <div class="col-sm-4 col-md-6 col-lg-7 col-xl-8"></div>
            <img id="personaje_1" src="@/assets/personaje_1.png" alt="personaje_1">
        </div>
    </div>
</template>

<script>
    import dotenv from 'dotenv'
    dotenv.config()
    export default {
        name: 'Start',
        data(){
            return {
                cssProps: {
                    backgroundImage: `url(${require('@/assets/ilustracion_fondo_con_margen-min.png')})`
                },
                caso_clinico: process.env.VUE_APP_GAME_ID
            };
        },
        methods: {
            Start: function(){
                router.push({name:"countdown"}).catch((e)=>{console.log(e)});
            }
        }
    }
</script>

<style scoped>

    #start{
        /*background-image: url('../assets/ilustracion_fondo_con_margen.png');*/
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: cover;
    }

    #personaje_1{
        position: absolute;
        right: 150px;
        bottom: 0px;
        width: 20%;
    }
    
</style>
