<template>
    <div class="activity1_2 w-100 h-100 d-flex justify-content-center align-items-center animate__animated animate__fadeIn">      
        <div class="row d-flex justify-content-center align-items-center activity-container w-100 h-100">
            <img id="esc_1_act_3" src="@/assets/esc_1_act_3.png" alt="esc_1_act_3" rel="preload">
            <div class="w-100 d-flex justify-content-end align-items-center feeback-back-btn mr-5">
                <button class="btn btn-action mt-3 mb-3 rounded check-btn mr-5" onclick="router.push({name:'scene1'});">Volver</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "act_03",
    mounted: function() {
        vueapp.$store.commit("setBackURL", "scene1");
        vueapp.$store.commit("setLastURL", "activity1_3");
        setActivityVisited('scene1', 'act_03');
        var act = getActivityStatus(1, 'act_03');
        if(act.completed == false)
            vueapp.$store.commit("setScore", vueapp.$store.state.score + 1);
        setActivityCompleted('scene1', 'act_03', true);
    }    
}
</script>

<style scoped>
    #esc_1_act_3{
        max-width: 100%;
        max-height: 100%;
    }
</style>