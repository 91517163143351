<template>
    <Radio 
        act_id="act_15"
        :scene_id="4"
        title="La glucemia en su sitio"
        intro_text="La glucemia tanto baja como alta, son  importantes"
        question="¿Qué actitud deberíamos tomar con respecto a la glucemia?"
        :options="[
            {
                id: 0,
                name: 'respuesta1',
                text: 'Bajarla por debajo de 140 mg/dl.',
                value: 0,
            },
            {
                id: 1,
                name: 'respuesta2',
                text: 'Infundir glucosado hasta subirla por encima de 180 mg/dl.',
                value: 1,
            },
            {
                id: 2,
                name: 'respuesta3',
                text: 'Dejarla igual porque está bien controlada.',
                value: 2,
            },
            {
                id: 3,
                name: 'respuesta4',
                text: 'Dejarla igual pero vigilarla porque si sube habría que corregirla.',
                value: 3,
            }
        ]" 
        :correct_answer=3
        incorrect_msg="La respuesta es incorrecta"
        clue_text="La glucemia varía cada minuto"
        :feedback="feedback_content"
        :scenario_finisher="true"
        :next_scenario="5"
        >
    </Radio>
</template>

<script>
import Radio from '../activitytype/Radio';
import Vue from 'vue';
import FeedbackEsc4Act4 from '../feedbacks/FeedbackEsc4Act4';

export default {
    data: function(){
        return {
            feedback_content: ""
        };
    },
    components: {
        Radio
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "scene4");
        vueapp.$store.commit("setLastURL", "activity4_4");
        setActivityVisited('scene4', 'act_15');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackEsc4Act4)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;
    }
}
</script>