<template>
    <div class="activity2_2order w-100 h-100">
        <Order 
            act_id="act_08" 
            :scene_id="2"
            title="Valoración RCV y educación terapéutica"
            question="Valorar categorización del RCV" 
            intro_text="Se realiza sesión de educación terapéutica con el objetivo de capacitar al paciente." 
            :options="[
                { id: 1, name: 'RCV bajo', cssclass: 'opcion1', val: 1 }, 
                { id: 2, name: 'RCV muy alto', cssclass: 'opcion2', val: 3 }, 
                { id: 3, name: 'RCV alto', cssclass: 'opcion3', val: 2 },
                { id: 4, name: 'RCV muy alto', cssclass: 'opcion4', val: 3 }, 
                { id: 5, name: 'RCV moderado', cssclass: 'opcion5', val: 4 }, 
                { id: 6, name: 'RCV muy alto', cssclass: 'opcion6', val: 3 }, 
                { id: 7, name: 'RCV extremo', cssclass: 'opcion7', val: 5 },
                { id: 8, name: 'RCV alto', cssclass: 'opcion8', val: 2 }, 
            ]" 
            :correct_order="[4, 2, 3, 3, 5, 2, 3, 1]"
            :feedback="feedback_content"
            incorrect_msg="La respuesta es incorrecta"
            clue_text="Determinar grado de RCV según condicionantes"
            :enunciados="[
                {
                    id: 1,
                    content: 'DM2 con menos de 5 años evolución'
                },
                {
                    id: 2,
                    content: 'DM2 más de 10 años de evolución sin LOD'
                },
                {
                    id: 3,
                    content: 'DM2 8 años de evolución y albuminuria'
                },
                {
                    id: 4,
                    content: 'DM2 de 3 años evolución y retinopatía'
                },
                {
                    id: 5,
                    content: 'DM2 buen control glucémico y ECV'
                },
                {
                    id: 6,
                    content: 'DM2 moderado control e HTA'
                },
                {
                    id: 7,
                    content: 'DM2 buen control y 3 FRCV'
                },
            ]"
            left_size="col-4"
            right_size="col-8"
            left_column="col-6"
            right_column="col-6"
            row_style="font-size: 13px;height: 52px;"
            :scenario_finisher="true"
            :next_scenario="3"
            >
        </Order>
    </div>
</template>

<script>
import Order from '../activitytype/Order';
import Vue from 'vue';
import FeedbackEsc2Act3 from '../feedbacks/FeedbackEsc2Act3';

export default {
    components: {
        Order
    },
    data:function(){
        return {            
            feedback_content: ""
        };
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "scene2");
        vueapp.$store.commit("setLastURL", "activity2_3");
        setActivityVisited('scene2', 'act_08');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackEsc2Act3)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;
    }
}
</script>