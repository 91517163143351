<template>
    <Radio 
        act_id="act_14"
        :scene_id="4"
        title="Se inicia estudio para diagnóstico diferencial"
        intro_text="Se solicita como prueba complementaria..."
        question="¿Qué valores de troponina sugieren isquemia miocárdica en un dolor torácico de una hora de evolución?"
        :options="[
            {
                id: 0,
                name: 'respuesta1',
                text: 'Valores superiores al rango de normalidad.',
                value: 0,
            },
            {
                id: 1,
                name: 'respuesta2',
                text: 'Los valores por encima del percentil 90 del rango de normalidad.',
                value: 1,
            },
            {
                id: 2,
                name: 'respuesta3',
                text: 'Cualquier valor elevado.',
                value: 2,
            },
            {
                id: 3,
                name: 'respuesta4',
                text: 'Es muy improbable una elevación de los valores de troponina ultrasensible hasta 3 horas después de iniciado un proceso isquémico.',
                value: 3,
            }
        ]" 
        :correct_answer=3
        incorrect_msg="La respuesta es incorrecta"
        clue_text="El tiempo cuenta y más en procesos agudos graves"
        :feedback="feedback_content">
    </Radio>
</template>

<script>
import Radio from '../activitytype/Radio';
import Vue from 'vue';
import FeedbackEsc4Act3 from '../feedbacks/FeedbackEsc4Act3';

export default {
    data: function(){
        return {
            feedback_content: ""
        };
    },
    components: {
        Radio
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "scene4");
        vueapp.$store.commit("setLastURL", "activity4_3");
        setActivityVisited('scene4', 'act_14');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackEsc4Act3)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;
    }
}
</script>