var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Order',{attrs:{"actid":_vm.actid,"title":"Acogida del paciente","broadcastMessage":"ha acabado la actividad orderactivity","question":"Ordena los elementos de la manera correcta","introText":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque tincidunt facilisis felis, eget aliquet sapien hendrerit id. Quisque lobortis sollicitudin lacinia. Aliquam porttitor vulputate tortor, et posuere eros suscipit id. Nam quis turpis sed ipsum elementum efficitur. Morbi semper est id urna vehicula ullamcorper. Vivamus vel eros nec ligula auctor suscipit. Nullam imperdiet ipsum at finibus sollicitudin. Proin non consectetur ipsum. ","options":[
        { id: 1, name: 'Evidencia A', cssclass: 'opcion1', val: 1}, 
        { id: 2, name: 'Evidencia B', cssclass: 'opcion2', val: 2}, 
        { id: 3, name: 'Evidencia E', cssclass: 'opcion3', val: 3}, 
        { id: 4, name: 'Evidencia B', cssclass: 'opcion4', val: 2},
        { id: 5, name: 'Evidencia A', cssclass: 'opcion5', val: 1},
        { id: 6, name: 'Evidencia A', cssclass: 'opcion6', val: 1},
        { id: 7, name: 'Evidencia B', cssclass: 'opcion7', val: 2}
    ],"correctOrder":[1, 1, 1, 2, 2, 2, 3],"feedback":"<ol>\n            <li>Modificación de estilos de vida para conseguir y mantener una pérdida de peso corporal inicial del 7% y mantener una actividad física moderada-intensa al menos 150min/semana. Evidencia A</li>\n            <li>Prescribir metformina si IMC<sup>3</sup>35Kg/m<sup>2</sup> en menores de 60 años y mujeres con antecedentes de DM gestacional. Evidencia A.</li>\n            <li>Abandono del hábito tabáquico y el uso de e-cigarrillos. Evidencia A</li>\n            <li>Alimentación equilibrada y saludable. Evidencia B</li>\n            <li>Utilización de las nuevas tecnologías para monitorizar las intervenciones sobre los estilos de vida. Evidencia B</li>\n            <li>Monitorizar el resto de Factores de riesgo cardiovascular. Evidencia B</li>\n            <li>Monitorizar al menos anualmente el desarrollo de la DM2 en pacientes con DM. Evidencia E</li>\n        </ol>","incorrectMsg":"La solución es incorrecta!","clueText":"Te será de utilidad conocer que disponemos de monofilamento en el Centro de Salud.","leftsize":"col-10","rightsize":"col-2","enunciados":[
        {
            id: 1,
            content: 'Modificación de estilos de vida para conseguir y mantener una pérdida de peso corporal inicial del 7% y mantener una actividad física moderada-intensa al menos 150 min/semana'
        },
        {
            id: 2,
            content: 'Prescribir metformina si IMC³35Kg/m2 en menores de 60 años y mujeres con antecedentes de DM gestacional'
        },
        {
            id: 3,
            content: 'Abandono del hábito tabáquico y el uso de e-cigarrillos'
        },
        {
            id: 4,
            content: 'Alimentación equilibrada y saludable'
        },
        {
            id: 5,
            content: 'Utilización de las nuevas tecnologías para monitorizar las intervenciones sobre los estilos de vida'
        },
        {
            id: 6,
            content: 'Monitorizar el resto de Factores de riesgo cardiovascular'
        },
        {
            id: 7,
            content: 'Monitorizar al menos anualmente el desarrollo de la DM2 en pacientes con DM'
        } ]}})}
var staticRenderFns = []

export { render, staticRenderFns }