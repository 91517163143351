<template>
    <MinChecks introText="1ª Actividad: Acogida del paciente" :options="[
        {
            id: 0,
            name: 'opcion1',
            text: 'Glucemia'
        },
        {
            id: 1,
            name: 'opcion2',
            text: 'Tensión arterial',
        },
        {
            id: 2,
            name: 'opcion3',
            text: 'Saturación de Oxígeno',
        },
        {
            id: 3,
            name: 'opcion4',
            text: 'Monitorización',
        },
        {
            id: 4,
            name: 'opcion5',
            text: 'Frecuencia cardíaca',
        },
        {
            id: 5,
            name: 'opcion6',
            text: 'Frecuencia respiratoria',
        },
        {
            id: 6,
            name: 'opcion7',
            text: 'Temperatura',
        },
        {
            id: 7,
            name: 'opcion8',
            text: 'Colocación de vía venosa',
        },
        {
            id: 8,
            name: 'opcion9',
            text: 'Pulsos periféricos',
        }
    ]" :correctAnswer="[
            0, 1, 2, 3, 4, 5,
        ]"></MinChecks>
</template>

<script>
import MinChecks from '../activitytype/MinChecks';

export default {
    props: {
        /**
         * Esto son datos de ejemplo para que se entienda mejor como debe ser el
         * array de opciones para las activities de tipo Checks.
         */
        
        
    },
    components: {
        MinChecks
    },
    mounted: function() {
        vueapp.$store.commit('setBackURL', '/scene1');
    }
}
</script>