<template>
    <QandA 
        title="Abordando la adherencia en la consulta de Atención Primaria"
        intro_text="La cita de hoy pretende provocar en José un cambio para mejorar la adherencia al tratamiento"
        :imgsrc="imgsrc"
        left_size="col-md-5"
        right_size="col-md-7"
        :questions="[
            {
                id: 0,
                question: '¿Manu, le han explicado a usted lo que le ha pasado y que consecuencias tiene?',
                answer: 'Me lo explicaron pero la verdad es que no lo entendí muy bien porque estaba asustado. Me dijeron que he tenido suerte, después de todo, salí muy bien.',
            },
            {
                id: 1,
                question: '¿Cómo valora todos los acontecimientos ocurridos en las últimas semanas?',
                answer: 'Verdaderamente, no he hecho las cosas como tenía que hacerlas, y seguro que eso ha sido el motivo de lo que me ha pasado. No me he cuidado como debía, y le confieso que tampoco tomaba el tratamiento como usted me recomendaba, y claro, al final me he encontrado lo que me he buscado yo solo.',
            },
            {
                id: 2,
                question: '¿Cree que si continuamos igual va a cambiar su situación?',
                answer: 'Mi padre me enseñó que si haces lo mismo obtendrás los mismos resultados y yo no quiero volver a pasarlo tan mal. Yo creía que me moría.',
            },
            {
                id: 3,
                question: '¿Y ahora, qué piensa hacer?',
                answer: 'Lo primero es reconocer que la diabetes mata aunque no duela, es mala amiga y cuando te das cuenta ya es tarde. Tengo que cambiar mi forma de vida y adaptar mis gustos a mis circunstancias y viceversa. Estoy seguro que con su ayuda podremos diseñar un plan de vida que sea llevadero para mi y no me cree más problemas.',
            }
        ]"></QandA>
</template>

<script>
import QandA from '../activitytype/QandA';

export default {
    components: {
        QandA,
    },
    data(){
        return {
            imgsrc: require("@/assets/personaje_1.png")
        };
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "scene5");
        vueapp.$store.commit("setLastURL", "activity5_2");
        setActivityVisited('scene5', 'act_17');
        var act = getActivityStatus(5, 'act_17');
        if(act.completed == false)
            vueapp.$store.commit("setScore", vueapp.$store.state.score + 1);
        setActivityCompleted('scene5', 'act_17', true);
    }
}
</script>