<template>
    <div class="activity2_1 w-100 h-100 d-flex justify-content-center align-items-center animate__animated animate__fadeIn">
        <div class="row activity-container w-100 h-100 d-flex justify-content-start align-items-start m-0 p-0 position-relative">
            <div class="col-md-5 mt-5 ml-5">
                <span class="text-oswald">Tras valorar los resultados, y realizando una comparación con los de visitas anteriores</span>
            </div>
            <div class="col-md-7">
            </div>
            <img id="esc_2_act_1" src="@/assets/esc_2_act_1.png" alt="esc_2_act_1" rel="preload">
            <div class="w-100 d-flex justify-content-end align-items-center feeback-back-btn mr-5">
                <button class="btn btn-action mt-3 mb-3 rounded check-btn mr-5" onclick="router.push({name:'scene2'});">Volver</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "act_06",
    mounted: function() {        
        vueapp.$store.commit("setBackURL", "scene2");
        vueapp.$store.commit("setLastURL", "activity2_1");
        setActivityVisited('scene2', 'act_06');
        var act = getActivityStatus(2, 'act_06');
        if(act.completed == false)
            vueapp.$store.commit("setScore", vueapp.$store.state.score + 1);
        setActivityCompleted('scene2', 'act_06', true);
    }
}
</script>

<style scoped>
    #esc_2_act_1{
        position: absolute;
        bottom: 0px;
        right: 0px;
        max-width: 100%;
        max-height: 100%;
    }
</style>