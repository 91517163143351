<template>
    <TextEntry :actid="actid" 
        introText='<h3 class="m-0">¡ALARMA!<br/>¡Hay un ladrón escondido en el <br/>Centro de Salud!</h3> <h5 class="m-0">Para apagar la alarma y poder continuar debes introducir las palabras clave</h5>' 
        entryPass="efficib-tesavel" 
        okText="Respuesta correcta" 
        failText="Respuesta incorrecta" 
        broadcastMessage="ha desbloqueado la pantalla de la alarma">
    </TextEntry>
</template>

<script>
import TextEntry from '../activitytype/TextEntry';

export default {
    data() { 
        return {
            actid: 77,
        }
    },
    components: {
        TextEntry
    },
    mounted: function() {
        fireNetworkAction('save-user-game-status', {
            user_id: this.$session.get('user_id'), 
            game_id: vueapp.$store.state.user.game.id,
            current_scene: 'code', 
            current_activity: '', 
            score: 0
        });
    }
}
</script>